import { FC } from 'react';

interface GoogleButtonProps {
  onClick: () => void;
  disabled?: boolean;
  isDark?: boolean;
}

export const GoogleButton: FC<GoogleButtonProps> = ({ onClick, disabled, isDark = true }) => {
  return (
    <button 
      onClick={onClick}
      disabled={disabled}
      className={`
        relative w-full h-12 px-6
        flex items-center justify-center
        ${isDark 
          ? 'bg-[#202124] hover:bg-[#303134] border-[#5f6368] hover:border-[#8e918f] text-[#e8eaed]' 
          : 'bg-white hover:bg-gray-50 border-gray-300 hover:border-gray-400 text-gray-700'
        }
        border rounded-lg
        transition-colors duration-200
        disabled:opacity-50 disabled:cursor-not-allowed
        focus:outline-none focus:ring-2 focus:ring-blue-500/20
      `}
    >
      <div className="absolute left-6">
        <img src="/google.svg" alt="Google logo" className="w-[18px] h-[18px]" />
      </div>
      <span className="text-sm font-medium">
        Sign in with Google
      </span>
    </button>
  );
}; 