import { BeaconDetails } from '../types/beacon';

class BeaconCache {
  private static instance: BeaconCache;
  private cache: { [key: string]: BeaconDetails } = {};

  private constructor() {}

  static getInstance(): BeaconCache {
    if (!BeaconCache.instance) {
      BeaconCache.instance = new BeaconCache();
    }
    return BeaconCache.instance;
  }

  set(key: string, details: BeaconDetails): void {
    this.cache[key] = details;
  }

  get(key: string): BeaconDetails | undefined {
    return this.cache[key];
  }

  has(key: string): boolean {
    return key in this.cache;
  }

  clear(): void {
    this.cache = {};
  }

  getAll(): { [key: string]: BeaconDetails } {
    return { ...this.cache };
  }
}

export const beaconCache = BeaconCache.getInstance(); 