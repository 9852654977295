import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface GlowTextProps {
  isDark: boolean;
  children: ReactNode;
  className?: string;
}

const GlowText = ({ isDark, children, className = '' }: GlowTextProps) => {
  const glowStyle = {
    textShadow: isDark
    ? '0 0 20px'
    : '0 0 20px',
  };

  return (
    <motion.span
      style={glowStyle}
      transition={{
        duration: 0.5,
        repeat: Infinity,
        
      }}
      className={`montserrat-h ${isDark ? "text-[#FFE07C]" : "text-blue-600"} transition-colors duration-300 ease-in-out inline-block ${className}`}
    >
      {children}
    </motion.span>
  );
};

export default GlowText;
