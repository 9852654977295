import { useEffect } from 'react';

export const usePreventZoom = () => {
  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    // Prevent zoom on double tap
    let lastTouchEnd = 0;
    const preventDoubleTapZoom = (e: TouchEvent) => {
      const now = Date.now();
      if (now - lastTouchEnd < 300) {
        e.preventDefault();
      }
      lastTouchEnd = now;
    };

    // Add event listeners
    document.addEventListener('touchmove', preventDefault, { passive: false });
    document.addEventListener('touchend', preventDoubleTapZoom, { passive: false });

    // Cleanup
    return () => {
      document.removeEventListener('touchmove', preventDefault);
      document.removeEventListener('touchend', preventDoubleTapZoom);
    };
  }, []);
}; 