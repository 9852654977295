export const tableStyles = {
  wrapper: {
    light: 'rounded-lg border border-gray-200 bg-white',
    dark: 'rounded-lg border border-gray-700 bg-gray-800'
  },
  tableContainer: 'overflow-x-auto sm:mx-0',
  table: 'w-full text-sm border-collapse min-w-full table-fixed',
  headerRow: {
    light: 'bg-gray-50',
    dark: 'bg-gray-700/50'
  },
  headerCell: {
    light: 'text-left p-2 sm:p-4 font-medium text-gray-600 transition-colors duration-200 whitespace-nowrap text-[11px] sm:text-sm',
    dark: 'text-left p-2 sm:p-4 font-medium text-gray-200 transition-colors duration-200 whitespace-nowrap text-[11px] sm:text-sm'
  },
  row: {
    light: 'group transition-colors hover:bg-gray-50 border-gray-200 cursor-pointer border-b',
    dark: 'group transition-colors hover:bg-gray-700/50 border-gray-700 cursor-pointer border-b'
  },
  cell: {
    light: 'p-2 sm:p-4 text-gray-600 align-top max-h-[120px] sm:max-h-[150px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent text-[11px] leading-tight sm:text-sm sm:leading-normal',
    dark: 'p-2 sm:p-4 text-gray-300 align-top max-h-[120px] sm:max-h-[150px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent text-[11px] leading-tight sm:text-sm sm:leading-normal'
  },
  truncated: 'truncate max-w-[80px] sm:max-w-[200px]',
  json: {
    light: 'font-mono whitespace-pre-wrap max-h-[120px] sm:max-h-[150px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 bg-gray-50 scrollbar-track-transparent p-1.5 sm:p-2 rounded text-[11px] leading-tight sm:text-sm',
    dark: 'font-mono whitespace-pre-wrap max-h-[120px] sm:max-h-[150px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 bg-gray-700/30 scrollbar-track-transparent p-1.5 sm:p-2 rounded text-[11px] leading-tight sm:text-sm'
  },
  searchWrapper: {
    light: 'p-2 sm:p-4 border-b border-gray-200',
    dark: 'p-2 sm:p-4 border-b border-gray-700'
  },
  searchIcon: {
    light: 'absolute left-2 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4 sm:w-5 sm:h-5',
    dark: 'absolute left-2 top-1/2 -translate-y-1/2 text-gray-500 w-4 h-4 sm:w-5 sm:h-5'
  },
  searchInput: {
    light: 'w-full rounded-lg border border-gray-200 bg-gray-50 text-gray-900 py-1.5 sm:py-2 px-7 sm:px-8 text-[11px] sm:text-sm outline-none transition-colors focus:border-blue-500 placeholder:text-[11px] sm:placeholder:text-sm',
    dark: 'w-full rounded-lg border border-gray-600 bg-gray-700/50 text-gray-200 py-1.5 sm:py-2 px-7 sm:px-8 text-[11px] sm:text-sm outline-none transition-colors focus:border-emerald-500 placeholder:text-[11px] sm:placeholder:text-sm'
  },
  clickableCell: {
    light: 'text-blue-500 hover:underline cursor-pointer text-[11px] sm:text-sm',
    dark: 'text-emerald-400 hover:underline cursor-pointer text-[11px] sm:text-sm'
  },
  emptyState: {
    light: 'p-3 sm:p-8 text-center text-gray-500 text-[11px] sm:text-sm',
    dark: 'p-3 sm:p-8 text-center text-gray-400 text-[11px] sm:text-sm'
  },
  columnBeacon: 'w-[75px] sm:w-[75px]',
  columnData: 'w-[300px] sm:w-[400px]',
  columnInfo: 'w-[100px] sm:w-[100px]',
  columnVoyage: 'w-[75px] sm:w-[75px]',
  columnDate: 'w-[120px] sm:w-[140px] whitespace-nowrap'
}; 