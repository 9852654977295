import React from 'react';
import { ArrowUpDown, ChevronUp, ChevronDown } from 'lucide-react';
import { Column } from './types';
import { tableStyles as styles } from './styles';

interface TableHeaderProps {
  column: Column;
  sortKey: string | null;
  sortDirection: 'asc' | 'desc';
  onSort: (key: string) => void;
  isDark: boolean;
}

export function TableHeader({ 
  column, 
  sortKey, 
  sortDirection, 
  onSort,
  isDark 
}: TableHeaderProps) {
  const isSorted = sortKey === column.key;

  return (
    <th 
      className={`${styles.headerCell[isDark ? 'dark' : 'light']} ${
        column.sortable ? `cursor-pointer ${isDark ? 'hover:bg-gray-700/50' : 'hover:bg-gray-100'}` : ''
      } ${column.className || ''}`}
      onClick={() => column.sortable && onSort(column.key)}
    >
      <div className="flex items-center gap-2">
        <span>{column.label}</span>
        {column.sortable && (
          <div className="flex items-center">
            {isSorted ? (
              sortDirection === 'asc' ? (
                <ChevronUp className={isDark ? 'text-emerald-500' : 'text-blue-500'} size={16} />
              ) : (
                <ChevronDown className={isDark ? 'text-emerald-500' : 'text-blue-500'} size={16} />
              )
            ) : (
              <ArrowUpDown className="text-gray-400" size={16} />
            )}
          </div>
        )}
      </div>
    </th>
  );
} 