import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LighthouseIcon } from '../../components/LighthouseIcon';
import { BeaconUser, Beacon } from '../../services/api3';
import { CodeBlock } from '../../components/CodeBlock';
import { BeaconsTable } from '../../components/BeaconsTable';
import { Check } from 'lucide-react';
import { BeaconDetails } from '../../types/beacon';
import { beaconCache } from '../../services/beaconCache';

export default function MyBeaconsPage({ isDark }: { isDark: boolean }) {
  const { user, isLoading: authLoading, captainKey } = useAuth();
  const [beaconKeys, setBeaconKeys] = useState<string[]>([]);
  const [selectedBeacon, setSelectedBeacon] = useState<string | null>(null);
  const [beaconContent, setBeaconContent] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [copiedData, setCopiedData] = useState(false);
  const [beaconDetails, setBeaconDetails] = useState<{ [key: string]: BeaconDetails }>({});
  const [lastCopiedType, setLastCopiedType] = useState('');
  const [lastCopiedPreview, setLastCopiedPreview] = useState('');
  const [originalBeaconKeys, setOriginalBeaconKeys] = useState<string[]>([]);
  const notificationTimeoutRef = useRef<NodeJS.Timeout>();
  const [loadingBeacons, setLoadingBeacons] = useState<Set<string>>(new Set());

  const loadBeacons = useCallback(async () => {
    if (!captainKey) return;
    
    setIsLoading(true);
    setError('');
    
    try {
      const beaconUser = new BeaconUser('');
      const captainData = await beaconUser.getCaptainData(captainKey);
      const beaconKeysList = captainData.beacon_keys || [];
      
      setBeaconKeys(beaconKeysList);
      setOriginalBeaconKeys(beaconKeysList);

      // Load cached beacons immediately
      const cachedDetails = beaconCache.getAll();
      if (Object.keys(cachedDetails).length > 0) {
        setBeaconDetails(cachedDetails);
      }
    } catch (err) {
      console.error('Error loading beacons:', err);
      setError('Failed to load your beacons. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [captainKey]);

  // Initial load
  useEffect(() => {
    if (captainKey) {
      loadBeacons();
    }
  }, [captainKey, loadBeacons]);

  const handleRefresh = useCallback(async () => {
    // Clear existing cache and state
    beaconCache.clear();
    setBeaconDetails({});
    setSelectedBeacon(null);
    setBeaconContent('');
    
    // Reload beacons
    await loadBeacons();
  }, [loadBeacons]);

  const isBeaconLoading = useCallback((beaconKey: string) => {
    return loadingBeacons.has(beaconKey);
  }, [loadingBeacons]);

  const loadBeaconDetails = useCallback(async (beaconKey: string) => {
    if (loadingBeacons.has(beaconKey) || beaconDetails[beaconKey]) {
      return;
    }

    setLoadingBeacons(prev => new Set([...prev, beaconKey]));

    try {
      const beacon = new Beacon(undefined, beaconKey);
      await beacon.refresh();
      const details = beacon.all as BeaconDetails;
      
      // Update both cache and state
      beaconCache.set(beaconKey, details);
      setBeaconDetails(prev => ({
        ...prev,
        [beaconKey]: details
      }));
    } catch (err) {
      console.error(`Error loading beacon ${beaconKey}:`, err);
    } finally {
      setLoadingBeacons(prev => {
        const next = new Set(prev);
        next.delete(beaconKey);
        return next;
      });
    }
  }, [loadingBeacons, beaconDetails]);

  const handleBeaconSelect = useCallback(async (beaconKey: string) => {
    setSelectedBeacon(beaconKey);
    
    // Check cache first
    const cached = beaconCache.get(beaconKey);
    if (cached) {
      setBeaconContent(JSON.stringify(cached, null, 2));
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      await loadBeaconDetails(beaconKey);
      const details = beaconCache.get(beaconKey);
      if (details) {
        setBeaconContent(JSON.stringify(details, null, 2));
      }
    } catch (err) {
      setError('Failed to load beacon content.');
    } finally {
      setIsLoading(false);
    }
  }, [loadBeaconDetails]);

  const handleCopyKey = async (key: string, preview: string, type: 'Beacon' | 'Voyage', e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(key);
      setLastCopiedPreview(preview);
      setLastCopiedType(type);
      
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
      
      setShowCopyNotification(true);
      notificationTimeoutRef.current = setTimeout(() => {
        setShowCopyNotification(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy key:', err);
    }
  };

  const handleCopyData = async () => {
    if (beaconContent) {
      try {
        const data = JSON.parse(beaconContent);
        if (data.beacon_data) {
          await navigator.clipboard.writeText(JSON.stringify(data.beacon_data, null, 2));
          setCopiedData(true);
          setTimeout(() => setCopiedData(false), 2000);
        }
      } catch (err) {
        console.error('Failed to copy beacon data:', err);
      }
    }
  };

  const formatKey = (key: string | undefined, type: 'beacon' | 'voyage') => {
    if (!key) return '...';
    
    if (type === 'beacon') {
      return `...${key.slice(-4)}`;
    } else {
      if (key.startsWith('v00')) {
        return `...${key.slice(-4)}`;
      }
      return key;
    }
  };

  const formatDate = (dateStr: string) => {
    try {
      const cleanDateStr = dateStr.endsWith('Z') 
        ? dateStr.slice(0, -1) 
        : dateStr;
        
      const date = new Date(cleanDateStr);
      if (isNaN(date.getTime())) return 'Invalid Date';
      
      return date.toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    } catch {
      return 'Invalid Date';
    }
  };

  const handleSort = (sortedKeys: string[]) => {
    setBeaconKeys(sortedKeys);
  };

  const memoizedBeaconDetails = useMemo(() => beaconDetails, [beaconDetails]);

  return (
    <>
      <div 
        aria-hidden={!showCopyNotification}
        className={`fixed left-1/2 -translate-x-1/2 z-[9999] px-4 py-2 rounded-lg shadow-lg 
          transition-all duration-500 ease-in-out flex items-center gap-2 top-24
          ${isDark ? 'bg-emerald-500/20 text-emerald-500' : 'bg-blue-500/20 text-blue-600'}
          ${showCopyNotification 
            ? 'opacity-100 transform translate-y-0' 
            : 'opacity-0 transform -translate-y-24 pointer-events-none'
          }`}
      >
        <Check className="h-4 w-4" />
        <span className="text-sm font-medium">
          Copied {lastCopiedType} {lastCopiedPreview}
        </span>
      </div>

      <div className="w-full px-4 pb-12 pt-24">
        <div className="max-w-[1920px] mx-auto">
          <div className="flex items-center mb-4">
            <LighthouseIcon className={`${isDark ? 'text-emerald-500' : 'text-blue-600'}`} size={48} />
            <h1 className="text-2xl font-light pl-2">My Beacons</h1>
          </div>

          <div className="mb-8">
            <h2 className={`text-lg font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
              Your Beacons
            </h2>
            <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Here are all the beacons associated with your account. Click on any beacon to view its contents.
            </p>
          </div>

          <div className="space-y-4">
            {error && (
              <div className={`mb-4 p-3 rounded-lg text-sm ${
                isDark ? 'bg-red-500/20 text-red-400' : 'bg-red-100 text-red-600'
              }`}>
                {error}
              </div>
            )}

            <BeaconsTable
              beaconKeys={beaconKeys}
              beaconDetails={memoizedBeaconDetails}
              selectedBeacon={selectedBeacon}
              onBeaconSelect={handleBeaconSelect}
              onCopyKey={handleCopyKey}
              formatKey={formatKey}
              formatDate={formatDate}
              isDark={isDark}
              isLoading={isLoading}
              onSort={handleSort}
              onLoadBeaconDetails={loadBeaconDetails}
              isBeaconLoading={isBeaconLoading}
              onRefresh={handleRefresh}
            />

            {selectedBeacon && (
              <div className="space-y-4">
                <div className="flex gap-2">
                  <button
                    onClick={handleCopyData}
                    className={`px-4 py-2 rounded-lg flex items-center gap-2
                      ${isDark 
                        ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                        : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'
                      }`}
                  >
                    {copiedData ? <Check size={16} /> : <Check size={16} />}
                    Copy Beacon Data
                  </button>
                </div>
                
                <CodeBlock
                  code={beaconContent}
                  isLoading={isLoading}
                  isDark={isDark}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
} 