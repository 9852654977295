import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { useTheme } from './hooks/useTheme';
import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import Page from './pages/Page';
import UseCasesAllies from './pages/UseCasesAllies';
import UseCasesAgent from './pages/UseCasesAgent';
import Developers from './pages/Developers';
import CreateBeaconPage from './pages/CreateBeaconPage';
import UseBeaconPage from './pages/UseBeaconPage';
// import ChatPage from './pages/ChatPage';
import LibrariesPage from './pages/LibrariesPage';
import AuthPage from './pages/AuthPage';
import ProfilePage from './pages/ProfilePage';
import TemplateProPage from './pages/ProPages/TemplateProPage';
import MyBeaconsPage from './pages/ProPages/MyBeaconsPage';
import TerminalPage from './pages/TerminalPage';
import { usePreventZoom } from './hooks/usePreventZoom';
import { AuthProvider } from './context/AuthContext';
import { useState, useEffect } from 'react';

export default function App() {
  // Initialize theme from localStorage, fallback to false (light) if not set
  const [isDark, setIsDark] = useState<boolean>(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme === 'true';
    } catch {
      return false;
    }
  });

  // Save theme preference whenever it changes
  useEffect(() => {
    try {
      localStorage.setItem('theme', String(isDark));
    } catch (error) {
      console.error('Failed to save theme preference:', error);
    }
  }, [isDark]);

  usePreventZoom();

  // Toggle function to update theme
  const toggleTheme = () => {
    setIsDark((prev: boolean) => !prev);
  };

  return (
    <AuthProvider>
      <Router>
        <div className={`min-h-screen flex flex-col transition-colors duration-300
          ${isDark 
            ? 'bg-gradient-to-b from-black to-black text-gray-100' 
            : 'bg-gradient-to-b from-white to-white text-gray-900'}`}>
          <Navigation 
            isDark={isDark} 
            onToggle={toggleTheme}
          />
          
          <main className="flex-1 flex flex-col">
            <Routes>
              <Route 
                path="/" 
                element={
                  <HomePage 
                    isDark={isDark} 
                  />
                } 
              />
              <Route path="/page" element={<Page />} />
              <Route path="/agents" element={<UseCasesAgent isDark={isDark} />} />
              <Route path="/allies" element={<UseCasesAllies isDark={isDark} />} />
              <Route path="/developers" element={<Developers isDark={isDark} />} />
              <Route path="/create" element={<CreateBeaconPage isDark={isDark} />} />
              <Route path="/use" element={<UseBeaconPage isDark={isDark} />} />
              {/* <Route path="/chat" element={<ChatPage isDark={isDark} />} /> */}
              <Route path="/libraries" element={<LibrariesPage isDark={isDark} />} />
              <Route path="/auth" element={<AuthPage isDark={isDark} />} />
              <Route path="/profile" element={<ProfilePage isDark={isDark} />} />
              <Route path="/pro/template" element={<TemplateProPage isDark={isDark} />} />
              <Route path="/pro/my-beacons" element={<MyBeaconsPage isDark={isDark} />} />
              <Route path="/terminal" element={<TerminalPage isDark={isDark} />} />
            </Routes>
          </main>

          <footer className={`text-center py-4 text-sm tracking-wider 
            ${isDark ? 'text-gray-700' : 'text-gray-400'}`}>
            SYSTEM ACTIVE • {new Date().getFullYear()}
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
}
