import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LighthouseIcon } from '../../components/LighthouseIcon';
import { motion, AnimatePresence } from 'framer-motion';
import { BeaconLibrary, Beacon } from '../../services/api3';
import { CodeBlock } from '../../components/CodeBlock';
import { Copy, Check, Trash2 } from 'lucide-react';

const EXAMPLE_LIBRARY_KEY = "l00053282147c052e9d935600c9d4a87418";

interface TemplateProPageProps {
  isDark: boolean;
}

// Cache interfaces
interface LibraryCache {
  [key: string]: string[];
}

interface BeaconCache {
  [key: string]: string;
}

export default function TemplateProPage({ isDark }: TemplateProPageProps) {
  const { user, isLoading: authLoading, captainKey } = useAuth();
  const [libraryKey, setLibraryKey] = useState('');
  const [beaconKeys, setBeaconKeys] = useState<string[]>([]);
  const [selectedBeacon, setSelectedBeacon] = useState<string | null>(null);
  const [beaconContent, setBeaconContent] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [copiedKey, setCopiedKey] = useState(false);
  const [copiedData, setCopiedData] = useState(false);
  
  // Client-side caches
  const [libraryCache, setLibraryCache] = useState<LibraryCache>({});
  const [beaconCache, setBeaconCache] = useState<BeaconCache>({});

  // If not authenticated, show message and link to auth page
  if (!authLoading && !user) {
    return (
      <div className="w-full max-w-5xl mx-auto px-4 pb-12 pt-24">
        <div className="flex items-center mb-4">
          <LighthouseIcon className={`${isDark ? 'text-emerald-500' : 'text-blue-600'}`} size={48} />
          <h1 className="text-2xl font-light pl-2">Protected Page</h1>
        </div>

        <div className={`text-center mt-12 ${isDark ? 'text-gray-200' : 'text-gray-800'}`}>
          <h1 className="text-2xl font-bold mb-4">Authentication Required</h1>
          <p className="mb-4">You need to be logged in to view this page.</p>
          <Link
            to="/auth"
            className={`inline-block px-6 py-2 rounded-lg font-medium ${
              isDark
                ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500'
                : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'
            }`}
          >
            Go to Login
          </Link>
        </div>
      </div>
    );
  }

  const handleLibrarySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    try {
      if (libraryCache[libraryKey]) {
        setBeaconKeys(libraryCache[libraryKey]);
      } else {
        const library = new BeaconLibrary(libraryKey, captainKey || undefined);
        await library.refresh();
        setBeaconKeys(library.library_data.beacon_keys);
        // Update cache
        setLibraryCache(prev => ({
          ...prev,
          [libraryKey]: library.library_data.beacon_keys
        }));
      }
    } catch (err) {
      setError('Failed to load library. Please check the library key and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBeaconSelect = useCallback(async (beaconKey: string) => {
    setIsLoading(true);
    setError('');
    setSelectedBeacon(beaconKey);

    try {
      if (beaconCache[beaconKey]) {
        setBeaconContent(beaconCache[beaconKey]);
      } else {
        const beacon = new Beacon(undefined, beaconKey, captainKey || undefined);
        await beacon.refresh();
        const content = JSON.stringify(beacon.all, null, 2);
        setBeaconContent(content);
        // Update cache
        setBeaconCache(prev => ({
          ...prev,
          [beaconKey]: content
        }));
      }
    } catch (err) {
      setError('Failed to load beacon content.');
    } finally {
      setIsLoading(false);
    }
  }, [beaconCache, captainKey]);

  const handleCopyKey = async () => {
    if (selectedBeacon) {
      await navigator.clipboard.writeText(selectedBeacon);
      setCopiedKey(true);
      setTimeout(() => setCopiedKey(false), 2000);
    }
  };

  const handleCopyData = () => {
    if (beaconContent) {
      try {
        const data = JSON.parse(beaconContent);
        if (data.beacon_data) {
          navigator.clipboard.writeText(JSON.stringify(data.beacon_data, null, 2))
            .then(() => {
              setCopiedData(true);
              setTimeout(() => setCopiedData(false), 2000);
            })
            .catch(err => {
              console.error('Failed to copy beacon data:', err);
            });
        }
      } catch (error) {
        console.error('Failed to parse beacon content:', error);
      }
    }
  };

  const handleExampleSubmit = async () => {
    setLibraryKey(EXAMPLE_LIBRARY_KEY);
    setIsLoading(true);
    
    try {
      // Check cache first
      if (libraryCache[EXAMPLE_LIBRARY_KEY]) {
        setBeaconKeys(libraryCache[EXAMPLE_LIBRARY_KEY]);
      } else {
        const library = new BeaconLibrary(EXAMPLE_LIBRARY_KEY, captainKey || undefined);
        await library.refresh();
        setBeaconKeys(library.library_data.beacon_keys);
        // Update cache
        setLibraryCache(prev => ({
          ...prev,
          [EXAMPLE_LIBRARY_KEY]: library.library_data.beacon_keys
        }));
      }
    } catch (err) {
      setError('Failed to load example library.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLibrarySubmit(e);
    }
  };

  const handleClear = () => {
    setLibraryKey('');
    setBeaconKeys([]);
    setBeaconContent('');
    setSelectedBeacon(null);
    setError('');
  };

  const inputClasses = `w-full rounded-lg px-4 py-3 text-sm
    ${isDark ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-white border-gray-200 text-gray-900'}
    border focus:ring-2 focus:ring-opacity-50
    ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`;

  const buttonClasses = `px-4 py-2 rounded-lg whitespace-nowrap
    ${isDark
      ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500'
      : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}`;

  const copyButtonClasses = `px-3 sm:px-4 py-2 rounded-lg flex-1 flex items-center justify-center gap-1 sm:gap-2
    ${isDark 
      ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
      : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
    text-xs sm:text-sm`;

  return (
    <div className="w-full max-w-5xl mx-auto px-4 pb-12 pt-24">
      <div className="flex items-center mb-4">
        <LighthouseIcon className={`${isDark ? 'text-emerald-500' : 'text-blue-600'}`} size={48} />
        <h1 className="text-2xl font-light pl-2">Beacon Pro</h1>
      </div>

      <div className="mb-8">
        <h2 className={`text-lg font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
          What are Beacon Libraries?
        </h2>
        <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
          Beacon Libraries allow you to organize and manage collections of related beacons. 
          Enter a library key to view all beacons within that library, or use our example library to explore the functionality.
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-[2fr_3fr] gap-4 lg:gap-8">
        {/* Left Column */}
        <div className="flex flex-col justify-between h-auto lg:h-[698px]">
          <div className="flex-grow">
            <label className={`block text-sm font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
              Library Key
            </label>
            <div className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Enter your library key to view all beacons in the library.
            </div>

            <div className="flex flex-col gap-2">
              <div className="relative">
                <input
                  type="text"
                  value={libraryKey}
                  onChange={(e) => setLibraryKey(e.target.value)}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter Library Key"
                  className={`${inputClasses} ${libraryKey.trim() ? 'pr-10' : ''}`}
                />
                {libraryKey.trim() && (
                  <button
                    onClick={handleClear}
                    className={`absolute right-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full
                      hover:bg-opacity-80
                      ${isDark ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                    aria-label="Clear input"
                  >
                    <Trash2 
                      size={16} 
                      className={isDark ? 'text-gray-400' : 'text-gray-500'} 
                    />
                  </button>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <button
                  onClick={handleExampleSubmit}
                  className={`px-4 py-2 rounded-lg w-full flex items-center justify-center gap-2
                    ${isDark ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                >
                  Use Example
                </button>

                <button
                  onClick={handleLibrarySubmit}
                  className={`${buttonClasses} w-full`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Load Library'}
                </button>
              </div>
            </div>

            {error && (
              <div className="text-red-500 mt-4 text-sm">
                {error}
              </div>
            )}

            {beaconKeys.length > 0 && (
              <div className={`flex-grow overflow-auto rounded-lg border mt-6
                ${isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}`}
              >
                <div className={`px-4 py-3 border-b text-sm font-medium
                  ${isDark ? 'bg-gray-900 border-gray-700' : 'bg-gray-50 border-gray-200'}`}
                >
                  Beacon Keys
                </div>
                <div className="divide-y divide-gray-200 dark:divide-gray-700">
                  {beaconKeys.map((key) => (
                    <button
                      key={key}
                      onClick={() => handleBeaconSelect(key)}
                      className={`w-full px-4 py-3 text-left text-sm
                        ${key === selectedBeacon
                          ? isDark
                            ? 'bg-gray-700'
                            : 'bg-gray-100'
                          : ''
                        }
                        ${isDark
                          ? 'hover:bg-gray-700'
                          : 'hover:bg-gray-50'
                        }`}
                    >
                      {key}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Column */}
        <div className="flex flex-col h-auto lg:h-[698px] mt-8 lg:mt-0">
          <div className="flex-grow">
            <AnimatePresence mode="wait">
              {beaconContent ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <CodeBlock
                    code={beaconContent}
                    isDark={isDark}
                    isLoading={isLoading}
                  />
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex flex-col sm:flex-row gap-2 sm:gap-4 mt-4"
                  >
                    <button
                      onClick={handleCopyKey}
                      className={copyButtonClasses}
                    >
                      {copiedKey ? <Check size={16} /> : <Copy size={16} />}
                      <span>Copy Beacon Key</span>
                    </button>
                    <button
                      onClick={handleCopyData}
                      className={copyButtonClasses}
                    >
                      {copiedData ? <Check size={16} /> : <Copy size={16} />}
                      <span>Copy Beacon Data</span>
                    </button>
                  </motion.div>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className={`h-full rounded-lg border
                    ${isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}
                    flex items-center justify-center px-4 text-sm`}
                  style={{ minHeight: '698px' }}
                >
                  <p className={`text-center ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    Select a beacon to see its contents here
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
} 