import React from 'react';
import { CodeBlock } from '../components/CodeBlock';

interface DevelopersProps {
  isDark: boolean;
}

const sampleCode = `{
    "beacon_key": "b002e0648dbcd543446c06201cd80022df7",
    "beacon_data": {
      "tech_news": [
        {
          "headline": "Apple's Latest Innovation Raises the Bar for AI in Smartphones",
          "summary": "Apple has unveiled its latest AI-powered smartphone feature, setting a new industry standard. The update integrates cutting-edge machine learning for improved user experience, hinting at Apple's deeper push into AI technologies. Analysts suggest this move could redefine the competitive landscape for mobile devices."
        },
        {
          "headline": "Google's Cloud AI Suite Gains Traction Among Enterprise Clients",
          "summary": "Google's Cloud AI Suite is capturing the attention of enterprise users, boasting new updates that promise faster analytics and better data security. The tech giant's investments in generative AI are now paying off, as more companies adopt its robust cloud solutions for critical operations."
        },
        {
          "headline": "Amazon Expands Robotics Division to Drive E-commerce Efficiency",
          "summary": "Amazon is doubling down on its robotics division, introducing advanced systems to optimize warehouse operations. These innovations aim to meet increasing demand and reduce fulfillment times, signaling a major shift in how Amazon approaches logistics."
        }
      ]
    },
    "beacon_info": "bestTech.com - Afternoon Digest Nov 22, 2024",
    "voyage_key": "best-tech-pm-digest",
    "beacon_metadata": {
      "created_at": "2024-11-22T20:11:52.405066+00:00Z",
      "updated_at": "2024-11-22T20:11:52.405066+00:00Z",
      "version": 1
    }
  }`;

const openAiFunctionDef = `{
  "type": "function",
  "function": {
    "name": "get_beacon_data",
    "description": "Retrieve data from a beacon by its key",
    "parameters": {
      "type": "object",
      "properties": {
        "beacon_key": {
          "type": "string",
          "description": "The unique identifier for the beacon"
        }
      },
      "required": ["beacon_key"]
    }
  }
}`;

const openAiIntegrationCode = `from openai import OpenAI
from beacon_sdk import Beacon

def get_beacon_data(beacon_key: str):
    """Retrieve data from a beacon"""
    beacon = Beacon(beacon_key=beacon_key)
    return beacon.get_beacon()

# Initialize OpenAI client
client = OpenAI()

# Define available functions
functions = [
    {
        "type": "function",
        "function": {
            "name": "get_beacon_data",
            "description": "Retrieve data from a beacon by its key",
            "parameters": {
                "type": "object",
                "properties": {
                    "beacon_key": {
                        "type": "string",
                        "description": "The unique identifier for the beacon"
                    }
                },
                "required": ["beacon_key"]
            }
        }
    }
]

# Example conversation with function calling
messages = [
    {"role": "user", "content": "Get the tech news from beacon b002e0648dbcd543446c06201cd80022df7"}
]

# Call the API with function definitions
response = client.chat.completions.create(
    model="gpt-4-turbo-preview",
    messages=messages,
    tools=functions,
    tool_choice="auto"
)

# Handle the response
message = response.choices[0].message

# Check if the model wants to call a function
if message.tool_calls:
    # Call the function
    for tool_call in message.tool_calls:
        if tool_call.function.name == "get_beacon_data":
            function_args = json.loads(tool_call.function.arguments)
            function_response = get_beacon_data(
                beacon_key=function_args["beacon_key"]
            )
            
            # Add function response to messages
            messages.append({
                "role": "tool",
                "tool_call_id": tool_call.id,
                "name": tool_call.function.name,
                "content": json.dumps(function_response)
            })

    # Get a new response from the model
    second_response = client.chat.completions.create(
        model="gpt-4-turbo-preview",
        messages=messages,
    )
    print(second_response.choices[0].message.content)`;

const Developers: React.FC<DevelopersProps> = ({ isDark }) => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="max-w-4xl mx-auto px-4 py-24 w-full">
        <h1 className={`text-4xl font-light mb-8 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
          Developers
        </h1>
        <p className="text-lg">
          Welcome to the Developers page. Here you will find resources and information to help you get started with our platform.
        </p>
        <p className="mt-2">
          Stay tuned for more updates and detailed guides on how to integrate and utilize our services effectively.
        </p>
        <div className="mt-4">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Sample Code
          </h2>
          <CodeBlock code={sampleCode} isDark={isDark} />
        </div>
        <div className="mt-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Python SDK Documentation
          </h2>
          <p className="text-lg">
            Learn how to integrate and utilize our Python SDK with your applications. Follow the steps below to get started.
          </p>

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 1: Installation
          </h3>
          <p>First, install the required package using pip:</p>
          <CodeBlock code={`pip install requests`} isDark={isDark} />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 2: Importing the Beacon Class
          </h3>
          <p>Import the necessary modules and the Beacon class into your Python script:</p>
          <CodeBlock code={`from beacon_sdk import Beacon`} isDark={isDark} />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 3: Initializing the Beacon Client
          </h3>
          <p>Initialize the Beacon client with your credentials:</p>
          <CodeBlock
            code={`beacon = Beacon(
    beacon_key="your_beacon_key",
    base_url="https://api.beaconkey.ai"
)`}
            isDark={isDark}
          />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 4: Creating a New Beacon
          </h3>
          <p>Create a new beacon by providing the necessary data:</p>
          <CodeBlock
            code={`new_beacon = beacon.create(beacon_data={"tech_news": []})`}
            isDark={isDark}
          />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 5: Fetching Beacon Data
          </h3>
          <p>Retrieve beacon data from the server:</p>
          <CodeBlock
            code={`beacon_data = beacon.get_beacon()`}
            isDark={isDark}
          />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 6: Understanding the Beacon Class
          </h3>
          <p>To maximize the potential of our Python SDK, it's crucial to understand the attributes and methods provided by the <code>Beacon</code> class. Below is a detailed overview:</p>

          <h4 className={`text-lg font-semibold mt-4 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Attributes
          </h4>
          <ul className="list-disc ml-6">
            <li><strong>beacon_key</strong>: <span className="font-normal">A unique identifier for the beacon.</span></li>
            <li><strong>beacon_info</strong>: <span className="font-normal">Additional information about the beacon.</span></li>
            <li><strong>beacon_data</strong>: <span className="font-normal">Data associated with the beacon, typically in dictionary format.</span></li>
            <li><strong>beacon_metadata</strong>: <span className="font-normal">Metadata related to the beacon, such as creation and update timestamps.</span></li>
            <li><strong>voyage_key</strong>: <span className="font-normal">A key representing the voyage associated with the beacon.</span></li>
            <li><strong>base_url</strong>: <span className="font-normal">The base URL of the Beacon server.</span></li>
          </ul>

          <h4 className={`text-lg font-semibold mt-4 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Methods
          </h4>
          <ul className="list-disc ml-6">
            <li>
              <strong><code>_validate_url(url: str) -&gt; bool</code></strong>: 
              <span className="font-normal">Validates the provided URL to ensure it has the correct format and SSL certificate.</span>
            </li>
            <li>
              <strong><code>__init__(self, beacon_data: Optional&lt;Union&lt;Dict, str&gt;&gt; = None, base_url: Optional[str] = None, beacon_key: Optional[str] = None, beacon_info: Optional[str] = None, voyage_key: Optional[str] = None)</code></strong>: 
              <span className="font-normal">Initializes the Beacon client with optional parameters to create or load a beacon.</span>
            </li>
            <li>
              <strong><code>_create(self) -&gt; 'Beacon'</code></strong>: 
              <span className="font-normal">Creates a new beacon on the server with the provided data.</span>
            </li>
            <li>
              <strong><code>get_beacon(self) -&gt; Dict&lt;str, Any&gt;</code></strong>: 
              <span className="font-normal">Retrieves all beacon data from the server.</span>
            </li>
            <li>
              <strong><code>refresh(self) -&gt; None</code></strong>: 
              <span className="font-normal">Refreshes the beacon data by fetching the latest information from the server.</span>
            </li>
            <li>
              <strong><code>__repr__(self) -&gt; str</code></strong>: 
              <span className="font-normal">Provides an unambiguous string representation of the Beacon object.</span>
            </li>
            <li>
              <strong><code>__str__(self)</code></strong>: 
              <span className="font-normal">Returns a human-readable string representation of the Beacon object.</span>
            </li>
          </ul>

          <h4 className={`text-lg font-semibold mt-4 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Example Usage of Attributes and Methods
          </h4>
          <p>Here's how you can utilize some of the key attributes and methods in your application:</p>
          <CodeBlock
            code={`# Initialize the Beacon client
beacon = Beacon(
    beacon_key="your_beacon_key",
    base_url="https://api.beaconkey.ai"
)

# Create a new beacon
new_beacon = beacon.create(beacon_data={"tech_news": []})

# Fetch beacon data
beacon_data = beacon.get_beacon()

# Refresh beacon data
beacon.refresh()

# Accessing attributes
print(beacon.beacon_key)
print(beacon.beacon_info)
print(beacon.voyage_key)
`}
            isDark={isDark}
          />
        </div>
        <div className="mt-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Using Beacons with OpenAI API
          </h2>
          <p className="text-lg mb-4">
            Learn how to integrate Beacons as tools with the OpenAI API. This allows AI models to directly access and use data from your beacons.
          </p>

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 1: Define the Beacon Function
          </h3>
          <p>First, define the function that OpenAI models can use to interact with beacons:</p>
          <CodeBlock code={openAiFunctionDef} isDark={isDark} />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Step 2: Complete Integration
          </h3>
          <p>Here's a complete example showing how to integrate Beacons with the OpenAI API:</p>
          <CodeBlock code={openAiIntegrationCode} isDark={isDark} />

          <h3 className={`text-xl font-semibold mt-6 mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Key Concepts
          </h3>
          <ul className="list-disc ml-6">
            <li>
              <strong>Function Definition:</strong> The function definition tells OpenAI's models how to interact with your beacons.
            </li>
            <li>
              <strong>Tool Calls:</strong> When the model needs beacon data, it will make a tool call that your code needs to handle.
            </li>
            <li>
              <strong>Response Handling:</strong> After getting data from a beacon, you need to send it back to the model for processing.
            </li>
            <li>
              <strong>Conversation Context:</strong> The model maintains context through the messages array, allowing for complex interactions.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Developers;