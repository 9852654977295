import React from 'react';
import { tableStyles as styles } from './styles';
import { Column } from './types';

interface TableCellProps {
  value: any;  // Changed from string to any since we might receive different types
  column: Column;
  isDark: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export function TableCell({ value, column, isDark, onClick }: TableCellProps) {
  // Convert value to string and handle undefined/null cases
  const stringValue = value?.toString() || '';
  
  const isJson = stringValue.startsWith('{') || stringValue.startsWith('[');
  let displayValue = stringValue;
  
  if (column.key === 'beacon_data' || column.key === 'beacon_info') {
    try {
      const unquoted = stringValue.replace(/^"|"$/g, '');
      if (unquoted.startsWith('{') || unquoted.startsWith('[')) {
        const parsed = JSON.parse(unquoted);
        displayValue = JSON.stringify(parsed, null, 2);
      } else {
        displayValue = unquoted;
      }
    } catch {
      displayValue = stringValue.replace(/^"|"$/g, '');
    }
  } else if (isJson) {
    try {
      const parsed = JSON.parse(stringValue);
      displayValue = JSON.stringify(parsed, null, 2);
    } catch {
      displayValue = stringValue;
    }
  }

  const content = isJson || column.key === 'beacon_data' || column.key === 'beacon_info' ? (
    <pre className={styles.json[isDark ? 'dark' : 'light']}>{displayValue}</pre>
  ) : column.onClick ? (
    <span 
      className={styles.clickableCell[isDark ? 'dark' : 'light']}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    >
      {displayValue}
    </span>
  ) : (
    <span className={column.truncate ? styles.truncated : 'block whitespace-pre-wrap'}>
      {displayValue}
    </span>
  );

  return (
    <td className={`${styles.cell[isDark ? 'dark' : 'light']} ${column.className || ''}`}>
      <div className="min-w-0">
        {content}
      </div>
    </td>
  );
}
