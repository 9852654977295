import React from 'react';
import { Search, X } from 'lucide-react';
import { tableStyles as styles } from './styles';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  total: number;
  filtered: number;
  isDark: boolean;
  onClear: () => void;
}

export function SearchBar({ value, onChange, total, filtered, isDark, onClear }: SearchBarProps) {
  return (
    <div className={styles.searchWrapper[isDark ? 'dark' : 'light']}>
      <div className="relative">
        <Search className={styles.searchIcon[isDark ? 'dark' : 'light']} />
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Search by full key or content..."
          className={styles.searchInput[isDark ? 'dark' : 'light']}
        />
        {value && (
          <button
            onClick={onClear}
            className={`absolute right-2 top-1/2 -translate-y-1/2 p-0.5 rounded-md
              ${isDark 
                ? 'hover:bg-gray-700/50 text-gray-400 hover:text-gray-300' 
                : 'hover:bg-gray-100 text-gray-600 hover:text-gray-700'}`}
          >
            <X size={14} />
          </button>
        )}
      </div>
      {value && (
        <div className={`mt-2 text-[11px] sm:text-xs ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
          Found {filtered} of {total} beacons
        </div>
      )}
    </div>
  );
} 