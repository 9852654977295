import { useState, KeyboardEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Copy, Check, Trash2 } from 'lucide-react';
import { CodeBlock } from '../components/CodeBlock';
import { Beacon } from '../services/api3';
import { useAuth } from '../context/AuthContext';
import { LighthouseIcon } from '../components/LighthouseIcon';

const EXAMPLE_BEACON_KEY = "b00295183474d92606bfb59a48d46b590ae";

interface ButtonConfig {
  label: string;
  onClick: (response: string) => void;
  icon?: React.ReactNode;
}

interface BeaconRetrieverProps {
  isDark: boolean;
  showHeader?: boolean;
  customHeader?: React.ReactNode;
  additionalControls?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  exampleKey?: string;
  customButtons?: ButtonConfig[];
}

const BeaconRetriever = ({ isDark, showHeader = true, customHeader, additionalControls, inputRef, exampleKey, customButtons }: BeaconRetrieverProps) => {
  const { captainKey } = useAuth();
  const [beaconKey, setBeaconKey] = useState('');
  const [response, setResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [copiedData, setCopiedData] = useState(false);
  const [copiedInfo, setCopiedInfo] = useState(false);

  const formatBeaconResponse = (beacon: any) => {
    return {
      beacon_key: beacon.all.beacon_key,
      beacon_data: beacon.all.beacon_data,
      beacon_info: beacon.all.beacon_info,
      voyage: beacon.all.voyage || {
        voyage_key: '',
        voyage_open: true
      },
      beacon_metadata: beacon.all.beacon_metadata || {
        created_at: '',
        updated_at: ''
      }
    };
  };

  const handleSubmit = async () => {
    if (!beaconKey.trim()) {
      setResponse(JSON.stringify({ error: 'Beacon key is required' }, null, 2));
      return;
    }

    setLoading(true);
    try {
      const beacon = new Beacon(undefined, beaconKey.trim(), captainKey || undefined);
      await beacon.refresh();
      const formattedResponse = formatBeaconResponse(beacon);
      setResponse(JSON.stringify(formattedResponse, null, 2));
    } catch (error) {
      setResponse(JSON.stringify({ 
        error: 'Failed to fetch beacon',
        details: error instanceof Error ? error.message : 'Unknown error'
      }, null, 2));
    }
    setLoading(false);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleCopyData = () => {
    if (response) {
      try {
        const data = JSON.parse(response);
        if (!data.beacon_data) {
          console.error('No beacon data available');
          return;
        }
        navigator.clipboard.writeText(JSON.stringify(data.beacon_data, null, 2))
          .then(() => {
            setCopiedData(true);
            setTimeout(() => setCopiedData(false), 2000);
          })
          .catch(err => {
            console.error('Failed to copy beacon data:', err);
          });
      } catch (error) {
        console.error('Failed to parse response:', error);
      }
    }
  };

  const handleCopyInfo = () => {
    if (response) {
      try {
        const data = JSON.parse(response);
        if (!data.beacon_info) {
          console.error('No beacon info available');
          return;
        }
        navigator.clipboard.writeText(data.beacon_info)
          .then(() => {
            setCopiedInfo(true);
            setTimeout(() => setCopiedInfo(false), 2000);
          })
          .catch(err => {
            console.error('Failed to copy beacon info:', err);
          });
      } catch (error) {
        console.error('Failed to parse response:', error);
      }
    }
  };

  const handlePasteAndSubmit = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      if (!clipboardText.trim()) {
        setResponse(JSON.stringify({ error: 'Clipboard is empty' }, null, 2));
        return;
      }
      
      setBeaconKey(clipboardText);
      
      setLoading(true);
      const beacon = new Beacon(undefined, clipboardText.trim(), captainKey || undefined);
      await beacon.refresh();
      const formattedResponse = formatBeaconResponse(beacon);
      setResponse(JSON.stringify(formattedResponse, null, 2));
    } catch (error) {
      setResponse(JSON.stringify({ 
        error: 'Failed to fetch beacon',
        details: error instanceof Error ? error.message : 'Unable to access clipboard'
      }, null, 2));
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (beaconKey.trim()) {
      handleSubmit();
    } else {
      handlePasteAndSubmit();
    }
  };

  const handleClear = () => {
    setBeaconKey('');
    setResponse(null);
  };

  const handleExampleSubmit = async () => {
    if (!exampleKey) return;
    
    setBeaconKey(exampleKey);
    setLoading(true);
    try {
      const beacon = new Beacon(undefined, exampleKey.trim(), captainKey || undefined);
      await beacon.refresh();
      const formattedResponse = formatBeaconResponse(beacon);
      setResponse(JSON.stringify(formattedResponse, null, 2));
    } catch (error) {
      setResponse(JSON.stringify({ 
        error: 'Failed to fetch beacon',
        details: error instanceof Error ? error.message : 'Unknown error'
      }, null, 2));
    }
    setLoading(false);
  };

  const inputClasses = `w-full rounded-lg px-4 py-3 text-sm
    ${isDark ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-white border-gray-200 text-gray-900'}
    border focus:ring-2 focus:ring-opacity-50
    ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`;

  const responseCodeBlock = (
    response ? (
      <CodeBlock 
        code={response} 
        isLoading={loading}
        isDark={isDark}
        key={isDark ? 'dark' : 'light'}
      />
    ) : null
  );

  const defaultButtons = [
    {
      label: 'Copy Beacon Data',
      onClick: handleCopyData,
      icon: copiedData ? <Check size={16} /> : <Copy size={16} />
    },
    {
      label: 'Copy Beacon Info',
      onClick: handleCopyInfo,
      icon: copiedInfo ? <Check size={16} /> : <Copy size={16} />
    }
  ];

  const buttonsToRender = customButtons || defaultButtons;

  return (
    <div className="flex-1 flex flex-col min-h-screen overflow-auto">
      <div className="w-full max-w-5xl mx-auto px-4 pb-2 sm:pb-12 pt-24">
        {showHeader && (
          customHeader || (
            <>
              <div className="flex items-center mb-4">
                <LighthouseIcon className={isDark ? 'text-emerald-500' : 'text-blue-600'} size={48} />
                <h1 className="text-2xl font-light pl-2">Use a Beacon</h1>
              </div>

              <div className="mb-8">
                <h2 className={`text-lg font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                  How to Use a Beacon
                </h2>
                <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  Enter a beacon key to retrieve its contents instantly. Each beacon key is unique and gives you access to the data stored within. 
                  Try our example beacon to see how it works, or use a key you've received to access specific content.
                </p>
              </div>
            </>
          )
        )}

        <div className="grid grid-cols-1 lg:grid-cols-[2fr_3fr] gap-1 sm:gap-8">
          {/* Left Column */}
          <div className="flex flex-col justify-between h-auto lg:h-[698px]">
            <div className="flex-grow">
              <label className={`block text-sm font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                Beacon Key
              </label>
              <div className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Enter your beacon key to retrieve its contents from the network.
              </div>
              <div className="relative">
                <input
                  ref={inputRef}
                  type="text"
                  value={beaconKey}
                  onChange={(e) => setBeaconKey(e.target.value)}
                  onKeyDown={handleKeyPress}
                  className={`${inputClasses} ${beaconKey.trim() ? 'pr-10' : ''}`}
                  placeholder="Enter beacon key..."
                />
                {beaconKey.trim() && (
                  <button
                    onClick={handleClear}
                    className={`absolute right-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full
                      hover:bg-opacity-80 transition-colors duration-200
                      ${isDark ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                    aria-label="Clear input"
                  >
                    <Trash2 
                      size={16} 
                      className={isDark ? 'text-gray-400' : 'text-gray-500'} 
                    />
                  </button>
                )}
              </div>
              {exampleKey && (
                <button
                  onClick={handleExampleSubmit}
                  className={`mt-2 px-4 py-2 rounded-lg w-full flex items-center justify-center gap-2
                    ${isDark ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}
                    text-sm`}
                >
                  Use Example
                </button>
              )}
              {additionalControls}
            </div>
            
            <button
              onClick={handleButtonClick}
              className={`mt-3 px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                ${isDark ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                  : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}`}
            >
              {beaconKey.trim() ? 'Get Beacon' : 'Paste Beacon Key & Get Beacon'}
            </button>
          </div>

          {/* Right Column */}
          <div className="flex flex-col h-auto lg:h-[698px] mt-8 lg:mt-0">
            <div className="flex-grow">
              <AnimatePresence mode="wait">
                {response ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {responseCodeBlock}
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`h-full rounded-lg border
                      ${isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}
                      flex items-center justify-center px-4 text-sm`}
                    style={{ minHeight: '698px' }}
                  >
                    <p className={`text-center ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                      Enter a beacon key to see its contents here
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <AnimatePresence>
              {response && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-col sm:flex-row gap-2 sm:gap-4 mt-4"
                >
                  {buttonsToRender.map((button, index) => (
                    <motion.button
                      key={index}
                      onClick={() => button.onClick(response)}
                      className={`px-4 py-3 rounded-lg flex-1 flex items-center justify-center gap-2
                        ${isDark ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                          : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                        transition-colors duration-200 text-sm`}
                    >
                      {button.icon}
                      <span>{button.label}</span>
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

const UseBeaconPage = ({ isDark }: BeaconRetrieverProps) => {
  return <BeaconRetriever 
    isDark={isDark} 
    exampleKey={EXAMPLE_BEACON_KEY}
    customHeader={
      <>
        <div className="flex items-center mb-4">
          <LighthouseIcon className={isDark ? 'text-emerald-500' : 'text-blue-600'} size={48} />
          <h1 className="text-2xl font-light pl-2">Use a Beacon</h1>
        </div>

        <div className="mb-8">
          <h2 className={`text-lg font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
            How to Use a Beacon
          </h2>
          <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Enter a beacon key to retrieve its contents instantly. Each beacon key is unique and gives you access to the data stored within. 
            Try our example beacon to see how it works, or use a key you've received to access specific content.
          </p>
        </div>
      </>
    }
  />;
};

export { BeaconRetriever };
export default UseBeaconPage;