import { useMemo, useEffect, useState } from 'react';
import { DataTable } from './table';
import { TableData, Column } from './table/types';
import { BeaconDetails } from '../types/beacon';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { RefreshCw } from 'lucide-react';
import { tableStyles as styles } from './table/styles';

interface BeaconsTableProps {
  beaconKeys: string[];
  beaconDetails: { [key: string]: BeaconDetails };
  selectedBeacon: string | null;
  onBeaconSelect: (key: string) => void;
  onCopyKey: (key: string, preview: string, type: 'Beacon' | 'Voyage', e: React.MouseEvent) => Promise<void>;
  formatKey: (key: string, type: 'beacon' | 'voyage') => string;
  formatDate: (dateStr: string) => string;
  isDark: boolean;
  isLoading: boolean;
  onLoadBeaconDetails: (beaconKey: string) => Promise<void>;
  isBeaconLoading: (beaconKey: string) => boolean;
  onRefresh: () => Promise<void>;
  onSort?: (sortedKeys: string[]) => void;
}

export function BeaconsTable({
  beaconKeys,
  beaconDetails,
  selectedBeacon,
  onBeaconSelect,
  onCopyKey,
  formatKey,
  formatDate,
  isDark,
  isLoading,
  onLoadBeaconDetails,
  isBeaconLoading,
  onRefresh,
  onSort
}: BeaconsTableProps) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [sortField, setSortField] = useState<string | null>('updated');
  const [sortOrder, setSortDirection] = useState<'asc' | 'desc'>('desc');
  
  // Load beacon details
  useEffect(() => {
    beaconKeys.forEach(beaconKey => {
      if (!beaconDetails[beaconKey] && !isBeaconLoading(beaconKey)) {
        onLoadBeaconDetails(beaconKey);
      }
    });
  }, [beaconKeys, beaconDetails, isBeaconLoading, onLoadBeaconDetails]);

  // Transform data for the table
  const tableData: TableData[] = useMemo(() => {
    return beaconKeys.map(key => {
      const details = beaconDetails[key];
      if (!details) return null;

      return {
        beaconKey: details.beacon_key,
        beaconKeyDisplay: formatKey(details.beacon_key, 'beacon'),
        beacon_data: JSON.stringify(details.beacon_data),
        beacon_info: JSON.stringify(details.beacon_info),
        voyageKey: details.voyage.voyage_key,
        voyageKeyDisplay: formatKey(details.voyage.voyage_key, 'voyage'),
        updated: details.beacon_metadata.updated_at,
        updatedDisplay: formatDate(details.beacon_metadata.updated_at),
        raw: details // Keep raw data for reference
      };
    }).filter(Boolean) as TableData[];
  }, [beaconKeys, beaconDetails, formatKey, formatDate]);

  // Format date to be more compact
  const formatCompactDate = (dateStr: string) => {
    try {
      // Handle the specific format: "2024-12-01T22:49:34.135796+00:00Z"
      const date = new Date(dateStr.replace(/\+00:00Z$/, 'Z'));
      if (isNaN(date.getTime())) return 'Invalid Date';
      
      return new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC'
      }).format(date);
    } catch (error) {
      console.error('Date parsing error:', error, 'for date:', dateStr);
      return 'Invalid Date';
    }
  };

  // Mobile-optimized columns
  const mobileColumns: Column[] = [
    { 
      key: 'beaconKeyDisplay', 
      label: 'D', 
      sortable: true, 
      truncate: true,
      className: styles.columnBeacon,
      onClick: (row: TableData, e: React.MouseEvent) => 
        onCopyKey(row.beaconKey, row.beaconKeyDisplay, 'Beacon', e)
    },
    { 
      key: 'beacon_data', 
      label: 'Beacon Data', 
      sortable: true,
      className: styles.columnData
    }
  ];

  // Desktop columns
  const desktopColumns: Column[] = [
    { 
      key: 'beaconKeyDisplay', 
      label: 'Beacon Key', 
      sortable: true, 
      truncate: true,
      className: styles.columnBeacon,
      onClick: (row: TableData, e: React.MouseEvent) => 
        onCopyKey(row.beaconKey, row.beaconKeyDisplay, 'Beacon', e)
    },
    { 
      key: 'beacon_data', 
      label: 'Beacon Data', 
      sortable: true,
      className: styles.columnData
    },
    { 
      key: 'beacon_info', 
      label: 'Beacon Info', 
      sortable: true,
      className: styles.columnInfo
    },
    { 
      key: 'voyageKeyDisplay', 
      label: 'Voyage Key', 
      sortable: true, 
      truncate: true,
      className: styles.columnVoyage,
      onClick: (row: TableData, e: React.MouseEvent) => 
        onCopyKey(row.voyageKey, row.voyageKeyDisplay, 'Voyage', e)
    },
    { 
      key: 'updatedDisplay', 
      label: 'Updated', 
      sortable: true,
      className: styles.columnDate,
      sortKey: 'updated'
    }
  ];

  // Transform data for mobile view
  const mobileData = useMemo(() => {
    return tableData.map(row => ({
      ...row,
      // Combine date into beacon_data for mobile
      beacon_data: `${row.beacon_data}\n${formatCompactDate(row.updated)}`
    }));
  }, [tableData]);

  // Transform data for desktop view
  const desktopData = useMemo(() => {
    return tableData.map(row => ({
      ...row,
      updatedDisplay: formatCompactDate(row.updated)
    }));
  }, [tableData]);

  const handleRowClick = (row: TableData) => {
    onBeaconSelect(row.beaconKey);
  };

  return (
    <div className="w-full space-y-2 sm:space-y-4">
      <div className="flex justify-between items-center px-2 sm:px-0">
        <div className={`text-[11px] sm:text-sm font-medium ${isDark ? 'text-gray-200' : 'text-gray-900'}`}>
          Your Beacons ({beaconKeys.length})
        </div>
        <button
          onClick={onRefresh}
          disabled={isLoading}
          className={`p-1 sm:p-2 rounded-lg transition-colors duration-200
            ${isDark 
              ? 'hover:bg-gray-700/50 text-gray-400 hover:text-gray-300' 
              : 'hover:bg-gray-100 text-gray-600 hover:text-gray-700'}
            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          title="Refresh beacons"
        >
          <RefreshCw 
            size={isMobile ? 14 : 16} 
            className={`${isLoading ? 'animate-spin' : ''}`}
          />
        </button>
      </div>

      <div className="rounded-lg overflow-hidden">
        <DataTable
          data={isMobile ? mobileData : desktopData}
          columns={isMobile ? mobileColumns : desktopColumns}
          isDark={isDark}
          onRowClick={handleRowClick}
          selectedRow={selectedBeacon}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={(field) => {
            if (sortField === field) {
              setSortDirection(sortOrder === 'asc' ? 'desc' : 'asc');
            } else {
              setSortField(field);
              setSortDirection('asc');
            }
            
            if (onSort) {
              const sortedData = [...tableData].sort((a, b) => {
                const aValue = a[field as keyof TableData];
                const bValue = b[field as keyof TableData];
                return sortOrder === 'asc' 
                  ? aValue > bValue ? 1 : -1
                  : aValue < bValue ? 1 : -1;
              });
              onSort(sortedData.map(item => item.beaconKey));
            }
          }}
        />
      </div>
    </div>
  );
}
