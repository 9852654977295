'use client'

import { useState, KeyboardEvent, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Copy, Check } from 'lucide-react';
import { CodeBlock } from '../components/CodeBlock';
import { Beacon } from '../services/api3';
import { useAuth } from '../context/AuthContext';
import { LighthouseIcon } from '../components/LighthouseIcon';

interface BeaconCreatorProps {
  isDark: boolean;
  showHeader?: boolean;
  customHeader?: React.ReactNode;
  additionalControls?: React.ReactNode;
  exampleData?: {
    beacon_data: string;
    info?: string;
    voyageKey?: string;
  };
}

interface APIError {
  error: string;
  details: string;
}

const BeaconCreator = ({ 
  isDark, 
  showHeader = true, 
  customHeader, 
  additionalControls,
  exampleData 
}: BeaconCreatorProps) => {
  const { captainKey } = useAuth();
  const [beacon_data, setData] = useState('');
  const [beaconInfo, setBeaconInfo] = useState('');
  const [voyageKey, setVoyageKey] = useState('');
  const [clearVoyageKey, setClearVoyageKey] = useState(true);
  const [response, setResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [voyageKeyError, setVoyageKeyError] = useState<string>('');

  const handleSubmit = async () => {
    if (!beacon_data.trim()) {
      setResponse(JSON.stringify({
        error: 'Data is required'
      }, null, 2));
      return;
    }

    setLoading(true);
    setVoyageKeyError('');
    
    try {
      const beacon = new Beacon({
        beacon_data: beacon_data.trim(),
        beacon_info: beaconInfo.trim()
      }, undefined, captainKey || undefined);
      
      if (voyageKey.trim()) {
        await beacon.createWithVoyage(voyageKey.trim());
      } else {
        await beacon.create();
      }
      
      setData('');
      setBeaconInfo('');
      if (clearVoyageKey) {
        setVoyageKey('');
      }
      
      setResponse(JSON.stringify(beacon.all, null, 2));
    } catch (error: any) {
      const errorResponse = error.response?.beacon_data;
      
      if (errorResponse?.message?.includes('voyage')) {
        setVoyageKeyError(errorResponse.message);
      }
      
      setResponse(JSON.stringify({ 
        error: 'Failed to create beacon',
        details: errorResponse?.message || error.message || 'Unknown error'
      }, null, 2));
    }
    setLoading(false);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.currentTarget.tagName.toLowerCase() === 'textarea') {
        if (!e.shiftKey) {
          e.preventDefault();
          handleSubmit();
        }
      } else {
        e.preventDefault();
        handleSubmit();
      }
    }
  };

  const handleCopyKey = () => {
    if (response) {
      const data = JSON.parse(response);
      navigator.clipboard.writeText(data.beacon_key);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleExampleSubmit = () => {
    if (!exampleData) return;
    
    setData(exampleData.beacon_data);
    if (exampleData.info) setBeaconInfo(exampleData.info);
    if (exampleData.voyageKey) setVoyageKey(exampleData.voyageKey);
  };

  const inputClasses = `w-full rounded-lg px-4 py-3 text-sm
    ${isDark ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-white border-gray-200 text-gray-900'}
    border focus:ring-2 focus:ring-opacity-50
    ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`;

  const responseCodeBlock = useMemo(() => (
    response ? (
      <CodeBlock 
        code={response} 
        isLoading={loading}
        isDark={isDark}
        key={isDark ? 'dark' : 'light'}
      />
    ) : null
  ), [response, loading, isDark]);

  return (
    <div className="flex-1 flex flex-col min-h-screen overflow-auto">
      <div className="w-full max-w-5xl mx-auto px-4 pb-2 sm:pb-12 pt-24">
        {showHeader && (
          customHeader || (
            <div className="flex items-center mb-4">
              <LighthouseIcon className={isDark ? 'text-emerald-500' : 'text-blue-600'} size={48} />
              <h1 className="text-2xl font-light pl-2">Create a Beacon</h1>
            </div>
          )
        )}

        <div className="mb-8">
          <h2 className={`text-lg font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
            What is a Beacon?
          </h2>
          <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Beacons are versatile data containers that can store any type of content - from simple text to complex JSON structures. 
            Each beacon comes with a unique key for easy retrieval and can be optionally linked to a voyage for better organization.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-[2fr_3fr] gap-4 lg:gap-8 min-h-0 lg:min-h-[600px]">
          <div className="flex flex-col h-auto">
            <div className="space-y-4">
              <div>
                <label className={`block text-sm font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                  Beacon Data
                </label>
                <div className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  The main content to be stored in your beacon. Any text string, JSON, structured or unstructured data works.
                </div>
                <textarea
                  value={beacon_data}
                  onChange={(e) => setData(e.target.value)}
                  onKeyDown={handleKeyPress}
                  className={`${inputClasses} resize-none h-[150px] lg:h-[200px]`}
                  placeholder="Enter beacon data (required)..."
                  required
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowY: 'auto'
                  }}
                />
              </div>

              <div>
                <label className={`block text-sm font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                  Beacon Info
                </label>
                <div className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  A short description of the data within the beacon and some context about its purpose. Target less than 100 characters.
                </div>
                <input
                  type="text"
                  value={beaconInfo}
                  onChange={(e) => setBeaconInfo(e.target.value)}
                  onKeyDown={handleKeyPress}
                  className={`${inputClasses} ${beaconInfo.length > 100 ? (isDark ? 'border-red-500' : 'border-red-400') : ''}`}
                  placeholder="Enter beacon info (recommended)..."
                />
                <div className="flex justify-start ml-1 mt-1">
                  <span className={`text-xs ${beaconInfo.length > 100 
                    ? (isDark ? 'text-red-500' : 'text-red-400')
                    : (isDark ? 'text-gray-400' : 'text-gray-600')}`}>
                    {beaconInfo.length}/100 characters
                  </span>
                </div>
              </div>

              <div>
                <label className={`block text-sm font-medium mb-2 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                  Voyage Key (Optional)
                </label>
                <div className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  A voyage is like a project. Link this beacon to an existing voyage. The voyage key must already exist.
                </div>
                <input
                  type="text"
                  value={voyageKey}
                  onChange={(e) => {
                    setVoyageKey(e.target.value);
                    setVoyageKeyError('');
                  }}
                  onKeyDown={handleKeyPress}
                  className={`${inputClasses} ${voyageKeyError ? (isDark ? 'border-red-500' : 'border-red-400') : ''}`}
                  placeholder="Enter existing voyage key (optional)..."
                />
                {voyageKeyError && (
                  <div className={`text-sm mt-1 ${isDark ? 'text-red-500' : 'text-red-400'}`}>
                    {voyageKeyError}
                  </div>
                )}
                <div className="flex items-center space-x-2 mt-2">
                  <input
                    type="checkbox"
                    id="clearVoyageKey"
                    checked={clearVoyageKey}
                    onChange={(e) => setClearVoyageKey(e.target.checked)}
                    className={`w-4 h-4 rounded ${isDark ? 'bg-gray-900 border-gray-700 text-emerald-500' : 'bg-white border-gray-300 text-blue-600'}`}
                  />
                  <label htmlFor="clearVoyageKey" className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
                    Clear Voyage Key on create
                  </label>
                </div>
              </div>

              {exampleData && (
                <button
                  onClick={handleExampleSubmit}
                  className={`px-4 py-2 rounded-lg w-full
                    ${isDark ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}
                    text-sm`}
                >
                  Use Example Data
                </button>
              )}

              {additionalControls}

              <button
                onClick={handleSubmit}
                className={`mt-3 px-4 py-2.5 rounded-lg w-full
                  ${isDark ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                    : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}`}
              >
                Create Beacon
              </button>
            </div>
          </div>

          <div className="flex flex-col h-auto lg:h-full mt-8 lg:mt-0">
            <div className="flex-grow overflow-auto">
              <AnimatePresence mode="wait">
                {response ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {responseCodeBlock}
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`h-full min-h-[400px] rounded-lg border
                      ${isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}
                      flex items-center justify-center px-4 text-sm`}
                  >
                    <p className={`text-center ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                      Create a beacon to see the response here
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <AnimatePresence>
              {response && (
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={handleCopyKey}
                  className={`px-4 py-3 rounded-lg flex items-center justify-center gap-2
                    ${isDark ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                      : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                    transition-colors duration-200 text-sm`}
                >
                  {copied ? <Check size={14} className="sm:w-4 sm:h-4" /> : <Copy size={14} className="sm:w-4 sm:h-4" />}
                  <span>{copied ? 'Copied!' : 'Copy Beacon Key'}</span>
                </motion.button>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateBeaconPage = ({ isDark }: BeaconCreatorProps) => {
  return <BeaconCreator 
    isDark={isDark} 
    customHeader={
      <div className="flex items-center mb-8">
        <LighthouseIcon className={isDark ? 'text-emerald-500' : 'text-blue-600'} size={48} />
        <h1 className="text-2xl font-light pl-2">Create a Beacon</h1>
      </div>
    }
  />;
};

export { BeaconCreator };
export default CreateBeaconPage;
