import { useState, useMemo } from 'react';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';
import { SearchBar } from './SearchBar';
import { Database } from 'lucide-react';
import type { TableData, Column } from './types';
import { tableStyles as styles } from './styles';

interface DataTableProps {
  data: TableData[];
  columns: Column[];
  isDark: boolean;
  onRowClick?: (row: TableData) => void;
  selectedRow?: string | null;
  isLoading?: boolean;
  isMobile?: boolean;
  sortField?: string | null;
  sortOrder?: 'asc' | 'desc';
  onSort?: (field: string) => void;
}

export function DataTable({ 
  data, 
  columns, 
  isDark,
  onRowClick,
  selectedRow,
  sortField: externalSortField,
  sortOrder: externalSortOrder,
  onSort: externalOnSort
}: DataTableProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [internalSortKey, setInternalSortKey] = useState<string | null>('updated');
  const [internalSortDirection, setInternalSortDirection] = useState<'asc' | 'desc'>('desc');

  // Use external or internal sort state
  const sortKey = externalSortField ?? internalSortKey;
  const sortDirection = externalSortOrder ?? internalSortDirection;

  const handleSort = (key: string) => {
    if (externalOnSort) {
      externalOnSort(key);
    } else {
      if (sortKey === key) {
        setInternalSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setInternalSortKey(key);
        setInternalSortDirection('asc');
      }
    }
  };

  const filteredAndSortedData = useMemo(() => {
    let processed = [...data];

    if (searchTerm) {
      const lowercaseSearch = searchTerm.toLowerCase();
      processed = processed.filter((item) => {
        // Search across all fields in the row
        return Object.values(item).some(value => 
          String(value).toLowerCase().includes(lowercaseSearch)
        );
      });
    }

    if (sortKey) {
      processed.sort((a, b) => {
        const aValue = a[sortKey as keyof TableData];
        const bValue = b[sortKey as keyof TableData];
        
        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return processed;
  }, [data, searchTerm, sortKey, sortDirection]);

  if (!data.length) {
    return (
      <div className={styles.wrapper[isDark ? 'dark' : 'light']}>
        <div className={styles.emptyState[isDark ? 'dark' : 'light']}>
          <Database className={`w-12 h-12 mx-auto mb-3 opacity-40 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
          <p>No data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper[isDark ? 'dark' : 'light']}>
      <SearchBar 
        value={searchTerm} 
        onChange={setSearchTerm}
        total={data.length}
        filtered={filteredAndSortedData.length}
        isDark={isDark}
        onClear={() => setSearchTerm('')}
      />
      
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headerRow[isDark ? 'dark' : 'light']}>
              {columns?.map((column) => (
                <TableHeader
                  key={column.key}
                  column={column}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                  isDark={isDark}
                />
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredAndSortedData.map((row) => (
              <tr 
                key={row.beaconKey}
                onClick={() => onRowClick?.(row)}
                className={`${styles.row[isDark ? 'dark' : 'light']} ${
                  selectedRow === row.beaconKey
                    ? isDark
                      ? 'bg-emerald-500/10 text-emerald-500'
                      : 'bg-blue-50 text-blue-600'
                    : ''
                }`}
              >
                {columns?.map((column) => (
                  <TableCell
                    key={column.key}
                    value={row[column.key]}
                    column={column}
                    isDark={isDark}
                    onClick={(e) => column.onClick?.(row, e)}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
