'use client'

const Page = () => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-col items-center pt-48 pb-8">
        <p className="text-xl">
        Good Day <br />
        <br />
        </p>
      </div>
    </div>
  );
};

export default Page;
