import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { BeaconUser } from '../services/api3';
import {
  signInWithGoogle,
  signInWithEmail,
  signUpWithEmail,
  signOutUser,
  onAuthStateChange
} from '../services/firebaseAuth';

interface AuthContextType {
  user: User | null;
  captainKey: string | null;
  isLoading: boolean;
  error: string | null;
  setCaptainKey: (key: string | null) => void;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [captainKey, setCaptainKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChange((user) => {
      setUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // When user changes, try to get or create captain key
  useEffect(() => {
    const initializeCaptainKey = async () => {
      if (user && !captainKey) {
        try {
          const idToken = await user.getIdToken();
          const beaconUser = new BeaconUser(idToken);
          const newCaptainKey = await beaconUser.register();
          setCaptainKey(newCaptainKey);
        } catch (error) {
          console.error('Failed to initialize captain key:', error);
          setError('Failed to initialize captain key');
        }
      }
    };

    initializeCaptainKey();
  }, [user, captainKey]);

  // After setting captainKey in the AuthContext useEffect:
  useEffect(() => {
    const fetchAdditionalKeys = async () => {
      if (user && captainKey) {
        const idToken = await user.getIdToken();
        const beaconUser = new BeaconUser(idToken);
        const captainData = await beaconUser.getCaptainData(captainKey);
        // Assuming captainData.additional_captain_keys is returned
        // Store them in state if needed or directly in the ProfilePage.
      }
    };

    fetchAdditionalKeys();
  }, [user, captainKey]);


  const handleSignIn = async (email: string, password: string) => {
    try {
      setError(null);
      setIsLoading(true);
      await signInWithEmail(email, password);
    } catch (error) {
      setError('Failed to sign in');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async (email: string, password: string) => {
    try {
      setError(null);
      setIsLoading(true);
      await signUpWithEmail(email, password);
    } catch (error) {
      setError('Failed to sign up');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await signInWithGoogle();
    } catch (error) {
      setError('Failed to sign in with Google');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await signOutUser();
      setCaptainKey(null);
    } catch (error) {
      setError('Failed to sign out');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        captainKey,
        isLoading,
        error,
        setCaptainKey,
        signIn: handleSignIn,
        signUp: handleSignUp,
        signInWithGoogle: handleGoogleSignIn,
        signOut: handleSignOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 