import { motion } from 'framer-motion';
import GlowText from './ui/GlowText';

const BeaconDefinition = ({ isDark }: { isDark: boolean }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full max-w-2xl px-8 mb-4"
    >
      <h2 className="playfair-display-title text-4xl mb-4">
        beacon
      </h2>
      <div className="roboto-regular mb-4">
        <span className="italic">noun</span><span className="px-4">|</span>bea·con<span className="px-4">|</span>ˈbē-kən
      </div>
      <div className="roboto-regular space-y-4">
        <p>
          <span className="">1 : a lighthouse or other signal for guidance</span><br />

        </p>
        <p>
          <GlowText isDark={isDark}>
            2 : a source of light or inspiration
          </GlowText>
        </p>
      </div>
    </motion.div>
  );
};

export default BeaconDefinition;
