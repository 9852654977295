import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { LighthouseIcon } from '../components/LighthouseIcon';
import { GoogleButton } from '../components/GoogleButton';

interface AuthPageProps {
  isDark: boolean;
}

export default function AuthPage({ isDark }: AuthPageProps) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, signUp, signInWithGoogle, isLoading } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        await signUp(email, password);
      } else {
        await signIn(email, password);
      }
      navigate('/profile');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Authentication failed');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate('/profile');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Google authentication failed');
    }
  };

  const inputClasses = `w-full rounded-lg px-4 py-3 text-sm mb-4
    ${isDark ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-white border-gray-200 text-gray-900'}
    border focus:ring-2 focus:ring-opacity-50
    ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`;

  const buttonClasses = `w-full px-4 py-3 rounded-lg font-medium
    ${isDark
      ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500'
      : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}`;

  return (
    <div className={`w-full max-w-md mx-auto px-4 pt-24 ${isDark ? 'dark' : ''}`}>
      <div className="flex items-center justify-center mb-8">
        <LighthouseIcon className={isDark ? 'text-emerald-500' : 'text-blue-600'} size={72} />
      </div>

      <h1 className="text-2xl font-light text-center mb-8">
        {isSignUp ? 'Create Account' : 'Sign In'}
      </h1>

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={inputClasses}
          required
        />

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={inputClasses}
          required
        />

        {error && (
          <div className="text-red-500 text-sm mb-4">
            {error}
          </div>
        )}

        <button
          type="submit"
          className={buttonClasses}
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : (isSignUp ? 'Sign Up' : 'Sign In')}
        </button>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className={`w-full border-t ${isDark ? 'border-gray-700' : 'border-gray-300'}`}></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className={`px-2 ${isDark ? 'bg-black text-gray-400' : 'bg-white text-gray-500'}`}>
              Or continue with
            </span>
          </div>
        </div>

        <GoogleButton
          onClick={handleGoogleSignIn}
          disabled={isLoading}
        />

        <button
          type="button"
          onClick={() => setIsSignUp(!isSignUp)}
          className="w-full text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
        >
          {isSignUp
            ? 'Already have an account? Sign in'
            : "Don't have an account? Sign up"}
        </button>
      </form>
    </div>
  );
} 