import axios from 'axios';

// Types
interface BeaconMetadata {
    created_at: string;
    updated_at: string;
}

interface VoyageInfo {
    voyage_key: string;
    voyage_open: boolean;
}

interface BeaconData {
    beacon_key: string;
    beacon_data: any;
    beacon_info: string;
    beacon_metadata: BeaconMetadata;
    voyage: VoyageInfo;
}

interface LibraryBeacon {
    beacon_data: any;
    beacon_info: string;
    beacon_key: string;
    updated_at: string;
    voyage: VoyageInfo;
}

interface LibraryData {
    library_key: string;
    beacon_keys: string[];
    beacons: LibraryBeacon[];
    created_at: string;
    updated_at: string;
}

interface VoyageData {
    voyage_key: string;
    beacon_keys: string[];
    invalid_beacon_keys?: string[];
    voyage_open: boolean;
    created_at?: string;
    updated_at?: string;
}

interface CaptainData {
    beacon_keys: string[];
    library_keys: string[];
    voyage_keys: string[];
}

export class Beacon {
    private static readonly baseUrl = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';
    private baseUrl: string;
    private captain_key?: string;
    public all: BeaconData;

    constructor(beacon_data?: any, beacon_key?: string, captain_key?: string) {
        this.baseUrl = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';
        this.captain_key = captain_key;
        this.all = {
            beacon_key: beacon_key || '',
            beacon_data: beacon_data?.beacon_data || '',
            beacon_info: beacon_data?.beacon_info || '',
            voyage: {
                voyage_key: '',
                voyage_open: true
            },
            beacon_metadata: {
                created_at: '',
                updated_at: ''
            }
        };
    }

    private mapResponseToBeaconData(result: any): BeaconData {
        if (result.status === 'success') {
            return {
                beacon_key: result.beacon_key,
                beacon_data: result.beacon_data,
                beacon_info: result.beacon_info,
                beacon_metadata: result.beacon_metadata,
                voyage: result.voyage
            };
        }
        throw new Error(result.message || 'Failed to map response data');
    }

    public async create(voyage_open: boolean = true): Promise<void> {
        try {
            const payload: any = {
                beacon_data: this.all.beacon_data,
                beacon_info: this.all.beacon_info,
                voyage_open
            };

            if (this.captain_key) {
                payload.captain_key = this.captain_key;
            }

            const response = await axios.post(`${this.baseUrl}/beacons`, payload);
            if (response.data.status === 'success') {
                this.all = this.mapResponseToBeaconData(response.data);
            } else {
                throw new Error(response.data.message || 'Failed to create beacon');
            }
        } catch (error) {
            console.error('Failed to create beacon:', error);
            throw error;
        }
    }

    public async createWithVoyage(voyage_key: string, voyage_open: boolean = true): Promise<void> {
        try {
            const payload: any = {
                beacon_data: this.all.beacon_data,
                beacon_info: this.all.beacon_info,
                voyage_key,
                voyage_open
            };

            if (this.captain_key) {
                payload.captain_key = this.captain_key;
            }

            const response = await axios.post(`${this.baseUrl}/beacons`, payload);
            if (response.data.status === 'success') {
                this.all = this.mapResponseToBeaconData(response.data);
            } else {
                throw new Error(response.data.message || 'Failed to create beacon with voyage');
            }
        } catch (error) {
            console.error('Failed to create beacon with voyage:', error);
            throw error;
        }
    }

    public async refresh(): Promise<void> {
        if (!this.all.beacon_key) {
            throw new Error('No beacon key set');
        }

        try {
            const response = await axios.get(`${this.baseUrl}/beacons/${this.all.beacon_key}`);
            if (response.data.status === 'success') {
                this.all = this.mapResponseToBeaconData(response.data);
            } else {
                throw new Error(response.data.message || 'Failed to refresh beacon');
            }
        } catch (error) {
            console.error('Failed to refresh beacon:', error);
            throw error;
        }
    }

    public async exists(): Promise<boolean> {
        if (!this.all.beacon_key) {
            throw new Error('No beacon key set');
        }

        try {
            const response = await axios.get(`${this.baseUrl}/beacons/${this.all.beacon_key}/exists`);
            return response.data.exists;
        } catch (error) {
            console.error('Failed to check beacon existence:', error);
            throw error;
        }
    }

    public static async getBatch(beacon_keys: string[]): Promise<{ [key: string]: BeaconData }> {
        try {
            const response = await axios.post(`${Beacon.baseUrl}/beacons/batch`, { beacon_keys });
            if (response.data.status === 'success') {
                return response.data.beacons;
            }
            throw new Error(response.data.message || 'Failed to get beacons batch');
        } catch (error) {
            console.error('Failed to get beacons batch:', error);
            throw error;
        }
    }
}

export class BeaconLibrary {
    private baseUrl: string;
    private captain_key?: string;
    public library_data: LibraryData;

    constructor(library_key: string | undefined = undefined, captain_key?: string) {
        this.baseUrl = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';
        this.captain_key = captain_key;
        this.library_data = {
            library_key: library_key || '',
            beacon_keys: [],
            beacons: [],
            created_at: '',
            updated_at: ''
        };
    }

    public async create(beacon_keys?: string[]): Promise<void> {
        if (!this.captain_key) {
            throw new Error('Captain key required to create library');
        }

        try {
            const response = await axios.post(`${this.baseUrl}/libraries`, {
                beacon_keys,
                captain_key: this.captain_key
            });

            if (response.data.status === 'success') {
                this.library_data = response.data.library_data;
            } else {
                throw new Error(response.data.message || 'Failed to create library');
            }
        } catch (error) {
            console.error('Failed to create library:', error);
            throw error;
        }
    }

    public async addBeacons(beacon_keys: string[]): Promise<void> {
        if (!this.captain_key) {
            throw new Error('Captain key required to modify library');
        }

        try {
            const response = await axios.post(
                `${this.baseUrl}/libraries/${this.library_data.library_key}/add`,
                {
                    beacon_keys,
                    captain_key: this.captain_key
                }
            );
            
            if (response.data.status === 'success') {
                this.library_data = response.data.library_data;
            } else {
                throw new Error(response.data.message || 'Failed to add beacons');
            }
        } catch (error) {
            console.error('Failed to add beacons:', error);
            throw error;
        }
    }

    public async removeBeacons(beacon_keys: string[]): Promise<void> {
        if (!this.captain_key) {
            throw new Error('Captain key required to modify library');
        }

        try {
            const response = await axios.post(
                `${this.baseUrl}/libraries/${this.library_data.library_key}/remove`,
                {
                    beacon_keys,
                    captain_key: this.captain_key
                }
            );
            
            if (response.data.status === 'success') {
                this.library_data = response.data.library_data;
            } else {
                throw new Error(response.data.message || 'Failed to remove beacons');
            }
        } catch (error) {
            console.error('Failed to remove beacons:', error);
            throw error;
        }
    }

    public async refresh(): Promise<void> {
        try {
            const response = await axios.get(
                `${this.baseUrl}/libraries/${this.library_data.library_key}`
            );
            
            if (response.data.status === 'success') {
                this.library_data = response.data.library_data;
            } else {
                throw new Error(response.data.message || 'Failed to refresh library');
            }
        } catch (error) {
            console.error('Failed to refresh library:', error);
            throw error;
        }
    }
}

export class BeaconVoyage {
    private baseUrl: string;
    public all: VoyageData;

    constructor(voyage_key: string) {
        this.baseUrl = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';
        this.all = {
            voyage_key,
            beacon_keys: [],
            voyage_open: true
        };
    }

    public async refresh(): Promise<void> {
        try {
            const response = await axios.get(`${this.baseUrl}/voyages/${this.all.voyage_key}`);

            if (response.data.status === 'success') {
                this.all = response.data.voyage_data;
            } else {
                throw new Error(response.data.message || 'Failed to get voyage data');
            }
        } catch (error) {
            console.error('Failed to refresh voyage:', error);
            throw error;
        }
    }

    public async getBeacons(): Promise<Beacon[]> {
        if (!this.all.beacon_keys || this.all.beacon_keys.length === 0) {
            return [];
        }

        try {
            const beacons = await Promise.all(
                this.all.beacon_keys.map(async (key) => {
                    const beacon = new Beacon(undefined, key);
                    await beacon.refresh();
                    return beacon;
                })
            );
            return beacons;
        } catch (error) {
            console.error('Failed to get beacons:', error);
            throw error;
        }
    }
}

export class BeaconUser {
    private baseUrl: string;
    private authToken: string;
    public captain_key?: string;

    constructor(authToken: string = '') {
        this.baseUrl = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';
        this.authToken = authToken;
    }

    public async register(): Promise<string> {
        if (!this.authToken) {
            throw new Error('Auth token required for registration');
        }

        try {
            const response = await axios.post(
                `${this.baseUrl}/users/register`,
                {},
                { headers: { Authorization: `Bearer ${this.authToken}` } }
            );
            
            if (response.data.status === 'success') {
                this.captain_key = response.data.captain_key;
                return this.captain_key;
            }
            throw new Error(response.data.message || 'Failed to register user');
        } catch (error) {
            console.error('Failed to register user:', error);
            throw error;
        }
    }

    public async validateCaptainKey(captain_key: string): Promise<boolean> {
        if (!this.authToken) {
            throw new Error('Auth token required for captain key validation');
        }

        try {
            const response = await axios.post(
                `${this.baseUrl}/captains/validate`,
                { captain_key },
                { headers: { Authorization: `Bearer ${this.authToken}` } }
            );
            return response.data.valid;
        } catch (error) {
            console.error('Failed to validate captain key:', error);
            throw error;
        }
    }

    public async getCaptainData(captain_key: string): Promise<CaptainData & { additional_captain_keys?: string[] }> {
        try {
            const response = await axios.post(`${this.baseUrl}/captains`, { captain_key });
            if (response.data.status === 'success') {
                return response.data.captain_data;
            }
            throw new Error(response.data.message || 'Failed to get captain data');
        } catch (error) {
            console.error('Failed to get captain data:', error);
            throw error;
        }
    }

    public async createAdditionalCaptainKey(): Promise<string> {
        if (!this.authToken) {
            throw new Error('Auth token required for creating captain key');
        }
        const response = await axios.post(
            `${this.baseUrl}/captains/keys/create`,
            {},
            { headers: { Authorization: `Bearer ${this.authToken}` } }
        );
        if (response.data.status === 'success') {
            return response.data.captain_key;
        }
        throw new Error(response.data.message || 'Failed to create captain key');
    }

    public async deleteAdditionalCaptainKey(key_to_delete: string): Promise<void> {
        if (!this.authToken) {
            throw new Error('Auth token required for deleting captain key');
        }
        const response = await axios.post(
            `${this.baseUrl}/captains/keys/delete`,
            { captain_key_to_delete: key_to_delete },
            { headers: { Authorization: `Bearer ${this.authToken}` } }
        );
        if (response.data.status !== 'success') {
            throw new Error(response.data.message || 'Failed to delete captain key');
        }
    }
}
