import React from 'react';

interface LighthouseIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const LighthouseIcon: React.FC<LighthouseIconProps> = ({
  size = 24,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* Base platform */}
      <path
        d="M6 20H18"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
      />

      {/* Main tower body */}
      <path
        d="M8 20L10 8H14L16 20"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />

      {/* Top house part */}
      <path
        d="M9 8L12 4L15 8"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* Light beams - enhanced for more radiance */}
      <g>
        {/* Left light beam */}
        <polygon
          points="9,6 2,2 2,12 9,8"
          fill={color}
          fillOpacity="0.15"
        />
        {/* Right light beam */}
        <polygon
          points="15,6 22,2 22,12 15,8"
          fill={color}
          fillOpacity="0.15"
        />
      </g>
    </svg>
  );
};

export default LighthouseIcon;
