import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Copy, Check, LogOut, Trash, Plus } from 'lucide-react';
import { LighthouseIcon } from '../components/LighthouseIcon';
import { useState, useEffect } from 'react';
import { BeaconUser } from '../services/api3';

interface ProfilePageProps {
  isDark: boolean;
}

export default function ProfilePage({ isDark }: ProfilePageProps) {
  const { user, captainKey, signOut, isLoading } = useAuth();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [additionalKeys, setAdditionalKeys] = useState<string[]>([]);
  const [keysLoading, setKeysLoading] = useState(true);
  const [keysError, setKeysError] = useState<string | null>(null);

  useEffect(() => {
    // Only redirect if we're not loading and there's no user
    if (!isLoading && !user) {
      navigate('/auth');
    }
  }, [user, navigate, isLoading]);

  useEffect(() => {
    const fetchKeys = async () => {
      if (user && captainKey) {
        setKeysLoading(true);
        setKeysError(null);
        try {
          const idToken = await user.getIdToken();
          const beaconUser = new BeaconUser(idToken);
          const data = await beaconUser.getCaptainData(captainKey);
          setAdditionalKeys(data.additional_captain_keys || []);
        } catch (error: any) {
          console.error('Failed to fetch additional keys:', error);
          setKeysError('Failed to fetch keys.');
        } finally {
          setKeysLoading(false);
        }
      } else {
        setKeysLoading(false);
      }
    };
    fetchKeys();
  }, [user, captainKey]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/auth');
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  const handleCopyKey = async (key: string) => {
    if (key) {
      await navigator.clipboard.writeText(key);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleCreateNewKey = async () => {
    if (!user) return;
    try {
      const idToken = await user.getIdToken();
      const beaconUser = new BeaconUser(idToken);
      const newKey = await beaconUser.createAdditionalCaptainKey();
      setAdditionalKeys((prev) => [...prev, newKey]);
    } catch (error) {
      console.error('Failed to create new captain key:', error);
    }
  };

  const handleDeleteKey = async (key: string) => {
    if (!user) return;
    try {
      const idToken = await user.getIdToken();
      const beaconUser = new BeaconUser(idToken);
      await beaconUser.deleteAdditionalCaptainKey(key);
      setAdditionalKeys((prev) => prev.filter((k) => k !== key));
    } catch (error) {
      console.error('Failed to delete captain key:', error);
    }
  };

  // Show loading state while checking authentication
  if (isLoading) {
    return (
      <div className={`min-h-screen ${isDark ? 'bg-black' : 'bg-white'} flex items-center justify-center`}>
        <div className={`text-lg ${isDark ? 'text-white' : 'text-gray-900'}`}>
          Loading...
        </div>
      </div>
    );
  }

  // Only redirect if we're not loading and there's no user
  if (!isLoading && !user) {
    return null;
  }

  return (
    <div className={`min-h-screen ${isDark ? 'bg-black' : 'bg-white'}`}>
      <div className="w-full max-w-5xl mx-auto px-4 pb-6 pt-24">
        <div className="flex items-center mb-4">
          <LighthouseIcon 
            className={isDark ? 'text-emerald-500' : 'text-blue-600'} 
            size={48} 
          />
          <h1 className={`text-2xl font-light pl-2 ${isDark ? 'text-white' : 'text-gray-900'}`}>
            Profile
          </h1>
        </div>

        <div className="mb-6">
          <h2 className={`text-lg font-medium mb-2 ${isDark ? 'text-emerald-400' : 'text-blue-600'}`}>
            Account Information
          </h2>
          <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Manage your account details and access your captain keys for creating and managing beacons.
          </p>
        </div>

        <div className={`rounded-lg p-6 mb-6 ${isDark ? 'bg-gray-900' : 'bg-gray-50'}`}>
          {/* Email Section */}
          <div className="mb-8">
            <label className={`block text-sm font-medium mb-1 ${isDark ? 'text-emerald-400' : 'text-blue-600'}`}>
              Email
            </label>
            <p className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Your account email address.
            </p>
            <p className={`${isDark ? 'text-gray-300 bg-gray-800' : 'text-gray-700 bg-white'} rounded-lg p-3`}>
              {user?.email}
            </p>
          </div>

          {/* Primary Captain Key Section */}
          <div className="mb-8">
            <label className={`block text-sm font-medium mb-1 ${isDark ? 'text-emerald-400' : 'text-blue-600'}`}>
              Captain Key (Primary)
            </label>
            <p className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Your primary unique identifier for creating and managing beacons.
            </p>
            <div className="flex items-center gap-2">
              <code className={`flex-1 p-3 rounded-lg font-mono text-sm break-all
                ${isDark ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-700 border border-gray-200'}`}>
                {captainKey || 'Loading...'}
              </code>
              <button
                onClick={() => captainKey && handleCopyKey(captainKey)}
                className={`p-3 rounded-lg transition-colors duration-200 ${
                  isDark 
                    ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                    : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'
                }`}
                disabled={!captainKey}
              >
                {copied ? <Check size={20} /> : <Copy size={20} />}
              </button>
            </div>
          </div>

          {/* Additional Captain Keys Section */}
          <div>
            <label className={`block text-sm font-medium mb-1 ${isDark ? 'text-emerald-400' : 'text-blue-600'}`}>
              Additional Captain Keys
            </label>
            <p className={`text-xs mb-2 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Create and manage additional keys for other services or environments.
            </p>

            <div className="mb-4">
              <button
                onClick={handleCreateNewKey}
                className={`px-4 py-2 rounded-lg flex items-center gap-2 transition-colors duration-200 ${
                  isDark
                    ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500'
                    : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'
                }`}
                disabled={keysLoading}
              >
                <Plus size={18} />
                <span>Create New Key</span>
              </button>
            </div>

            {keysLoading ? (
              <p className={`${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Loading keys...
              </p>
            ) : keysError ? (
              <p className={`${isDark ? 'text-red-400' : 'text-red-600'}`}>
                {keysError}
              </p>
            ) : (
              <ul className="space-y-2">
                {additionalKeys.map((key) => (
                  <li key={key} className="flex items-center gap-2">
                    <code className={`flex-1 p-3 rounded-lg font-mono text-sm break-all
                      ${isDark ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-700 border border-gray-200'}`}>
                      {key}
                    </code>
                    <button
                      onClick={() => handleCopyKey(key)}
                      className={`p-3 rounded-lg transition-colors duration-200 ${
                        isDark
                          ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500'
                          : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'
                      }`}
                    >
                      {copied ? <Check size={20} /> : <Copy size={20} />}
                    </button>
                    <button
                      onClick={() => handleDeleteKey(key)}
                      className={`p-3 rounded-lg transition-colors duration-200 ${
                        isDark
                          ? 'bg-red-500/20 hover:bg-red-500/30 text-red-500'
                          : 'bg-red-50 hover:bg-red-100 text-red-600'
                      }`}
                    >
                      <Trash size={20} />
                    </button>
                  </li>
                ))}
                {additionalKeys.length === 0 && !keysLoading && !keysError && (
                  <p className={`${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    No additional keys created yet.
                  </p>
                )}
              </ul>
            )}
          </div>
        </div>

        <div className="flex justify-center sm:justify-end">
          <button
            onClick={handleSignOut}
            className={`px-6 py-3 rounded-lg flex items-center gap-2 transition-colors duration-200 ${
              isDark 
                ? 'bg-red-900/30 hover:bg-red-900/50 text-red-400' 
                : 'bg-red-50 hover:bg-red-100 text-red-600'
            }`}
          >
            <LogOut size={18} />
            <span>Sign Out</span>
          </button>
        </div>
      </div>
    </div>
  );
}
