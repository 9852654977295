import { Link } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';
import { useState, useRef, useEffect } from 'react';
import { LighthouseIcon } from '../components/LighthouseIcon';
import { useAuth } from '../context/AuthContext';
import { UserCircle, ChevronDown } from 'lucide-react';

const NavLink = ({ 
  to, 
  children, 
  isDark, 
  onClick,
  isBeacon = false,
  showTerminal = false,
  setShowTerminal = undefined
}: { 
  to: string; 
  children: React.ReactNode; 
  isDark: boolean; 
  onClick: () => void;
  isBeacon?: boolean;
  showTerminal?: boolean;
  setShowTerminal?: (show: boolean) => void;
}) => (
  <Link 
    to={to} 
    onClick={(e) => {
      if (isBeacon && showTerminal) {
        setShowTerminal?.(false);
      }
      onClick();
    }}
    className={`text-lg font-light flex items-center space-x-2 ${isDark ? 'text-white hover:text-gray-300' : 'text-gray-900 hover:text-gray-700'}`}
  >
    {children}
  </Link>
);

const Navigation = ({ 
  isDark, 
  onToggle
}: { 
  isDark: boolean;
  onToggle: () => void;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [showProMenu, setShowProMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setShowProMenu(false);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const signInButtonClass = `px-3 py-1.5 rounded-lg flex items-center justify-center gap-2 text-sm
    ${isDark 
      ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
      : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'
    }`;

  const dropdownButtonClass = `relative px-3 py-4 rounded-lg flex items-center justify-center gap-2
    ${isDark 
      ? 'hover:bg-emerald-500/10' 
      : 'hover:bg-blue-500/10'
    }`;

  const dropdownMenuClass = `absolute left-0.5 top-full mt-1 w-48 py-2 rounded-lg shadow-lg border z-50
    ${isDark 
      ? 'bg-gray-900 border-gray-700' 
      : 'bg-white border-gray-200'
    }`;

  const dropdownItemClass = `w-full px-4 py-2 text-left text-sm flex items-center gap-2
    ${isDark
      ? 'hover:bg-gray-800 text-gray-200'
      : 'hover:bg-gray-50 text-gray-700'
    }`;

  const closeMenuAndScrollTop = () => {
    closeMenu();
    window.scrollTo(0, 0);
  };

  const MobileNavLinks = () => (
    <>
      {showProMenu ? (
        <div className="flex flex-col space-y-4">
          <NavLink to="/profile" isDark={isDark} onClick={closeMenuAndScrollTop}>Profile</NavLink>
          {/* <NavLink to="/pro/template" isDark={isDark} onClick={closeMenuAndScrollTop}>Template</NavLink> */}
          <NavLink to="/pro/my-beacons" isDark={isDark} onClick={closeMenuAndScrollTop}>My Beacons</NavLink>
          {/* <NavLink to="/pro/libraries" isDark={isDark} onClick={closeMenuAndScrollTop}>Pro Libraries</NavLink> */}
          {/* <NavLink to="/pro/analytics" isDark={isDark} onClick={closeMenuAndScrollTop}>Analytics</NavLink> */}
          {/* <NavLink to="/pro/settings" isDark={isDark} onClick={closeMenuAndScrollTop}>Settings</NavLink> */}
        </div>
      ) : (
        <div className="flex flex-col space-y-4">
          <NavLink to="/create" isDark={isDark} onClick={closeMenuAndScrollTop}>Create</NavLink>
          <NavLink to="/use" isDark={isDark} onClick={closeMenuAndScrollTop}>Use</NavLink>
          {/* <NavLink to="/chat" isDark={isDark} onClick={closeMenuAndScrollTop}>Chat</NavLink> */}
          <NavLink to="/libraries" isDark={isDark} onClick={closeMenuAndScrollTop}>Libraries</NavLink>
          <NavLink to="/agents" isDark={isDark} onClick={closeMenuAndScrollTop}>Agents</NavLink>
          <NavLink to="/allies" isDark={isDark} onClick={closeMenuAndScrollTop}>Allies</NavLink>
          <NavLink to="/developers" isDark={isDark} onClick={closeMenuAndScrollTop}>Developers</NavLink>
        </div>
      )}
    </>
  );

  const ProfileButton = () => (
    <div className="relative hidden lg:block" ref={dropdownRef}>
      <button
        className={dropdownButtonClass}
        onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
      >
        <UserCircle className={`w-6 h-6 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`} />
        <ChevronDown 
          className={`w-4 h-4 ${isDark ? 'text-emerald-500' : 'text-blue-600'} transition-transform duration-200
            ${isProfileDropdownOpen ? 'transform rotate-180' : ''}`}
        />
      </button>

      {isProfileDropdownOpen && (
        <div className={dropdownMenuClass}>
          <Link 
            to="/profile" 
            className={dropdownItemClass} 
            onClick={() => {
              setIsProfileDropdownOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            Profile
          </Link>
          {/* <Link 
            to="/pro/template" 
            className={dropdownItemClass} 
            onClick={() => {
              setIsProfileDropdownOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            Template
          </Link> */}
          <Link 
            to="/pro/my-beacons" 
            className={dropdownItemClass} 
            onClick={() => {
              setIsProfileDropdownOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            My Beacons
          </Link>
          {/* <Link 
            to="/pro/analytics" 
            className={dropdownItemClass} 
            onClick={() => {
              setIsProfileDropdownOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            Analytics
          </Link> */}
          {/* <Link 
            to="/pro/settings" 
            className={dropdownItemClass} 
            onClick={() => {
              setIsProfileDropdownOpen(false);
              window.scrollTo(0, 0);
            }}
          >
            Settings
          </Link> */}
        </div>
      )}
    </div>
  );

  const MobileProfileButton = () => (
    <button
      className={`${dropdownButtonClass} lg:hidden`}
      onClick={() => setShowProMenu(!showProMenu)}
    >
      <UserCircle className={`w-6 h-6 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`} />
      <ChevronDown 
        className={`w-4 h-4 ${isDark ? 'text-emerald-500' : 'text-blue-600'} transition-transform duration-200
          ${showProMenu ? 'transform rotate-180' : ''}`}
      />
    </button>
  );

  const handleBeaconClick = () => {
    closeMenu();
    window.scrollTo(0, 0);
  };

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 ${isDark ? 'bg-black/80' : 'bg-white/80'} backdrop-blur-sm border-b ${isDark ? 'border-gray-800' : 'border-gray-200'}`}>
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <NavLink 
            to="/" 
            isDark={isDark} 
            onClick={handleBeaconClick}
            isBeacon={true}
          >
            <LighthouseIcon className={`w-6 h-6 ${isDark ? 'text-white' : 'text-black'}`} />
            <span>Beacon</span>
          </NavLink>

          <div className="hidden lg:flex items-center space-x-8">
            <NavLink to="/create" isDark={isDark} onClick={closeMenu}>Create</NavLink>
            <NavLink to="/use" isDark={isDark} onClick={closeMenu}>Use</NavLink>
            {/* <NavLink to="/chat" isDark={isDark} onClick={closeMenu}>Chat</NavLink> */}
            <NavLink to="/libraries" isDark={isDark} onClick={closeMenu}>Libraries</NavLink>
            <NavLink to="/agents" isDark={isDark} onClick={closeMenu}>Agents</NavLink>
            <NavLink to="/allies" isDark={isDark} onClick={closeMenu}>Allies</NavLink>
            <NavLink to="/developers" isDark={isDark} onClick={closeMenu}>Developers</NavLink>
            {user ? (
              <ProfileButton />
            ) : (
              <NavLink to="/auth" isDark={isDark} onClick={closeMenu}>
                <span className={signInButtonClass}>Sign In</span>
              </NavLink>
            )}
            <ThemeToggle isDark={isDark} onToggle={onToggle} />
          </div>

          <div className="lg:hidden flex items-center space-x-2">
            {isMenuOpen && (
              <>
                {user ? (
                  <MobileProfileButton />
                ) : (
                  <NavLink to="/auth" isDark={isDark} onClick={closeMenu}>
                    <span className={signInButtonClass}>Sign In</span>
                  </NavLink>
                )}
                <ThemeToggle isDark={isDark} onToggle={onToggle} />
              </>
            )}
            <button
              onClick={toggleMenu}
              className="p-1.5"
              aria-label="Toggle menu"
            >
              <div className={`w-6 h-0.5 mb-1.5 transition-all ${isDark ? 'bg-white' : 'bg-black'} ${isMenuOpen ? 'transform rotate-45 translate-y-2' : ''}`}></div>
              <div className={`w-6 h-0.5 mb-1.5 ${isDark ? 'bg-white' : 'bg-black'} ${isMenuOpen ? 'opacity-0' : ''}`}></div>
              <div className={`w-6 h-0.5 ${isDark ? 'bg-white' : 'bg-black'} ${isMenuOpen ? 'transform -rotate-45 -translate-y-2' : ''}`}></div>
            </button>
          </div>
        </div>

        <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'} pb-4`}>
          {user ? (
            <>
              <MobileNavLinks />
            </>
          ) : (
            <>
              <MobileNavLinks />
            </>
          )}
        </div>
      </div>
    </nav>
  );
};
export default Navigation;

