import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BeaconDefinition from '../components/BeaconDefinition';
import { useNavigate } from 'react-router-dom';

export const HomePage = ({ isDark }: { isDark: boolean }) => {
  const navigate = useNavigate();
  const [showDefinition, setShowDefinition] = useState(true);

  const handleBeginDemo = () => {
    navigate('/terminal');
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-col items-center pt-36 min-h-screen relative">
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key="definition"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="w-full max-w-4xl mx-auto px-4"
          >
            <BeaconDefinition isDark={isDark} />
            
            <div className={`w-full max-w-4xl my-8 border-t ${isDark ? 'border-gray-800' : 'border-gray-200'}`} />

            <div className="w-full max-w-4xl px-4 sm:px-8 mb-32 sm:mb-0">
              <h1 className={`text-lg sm:text-xl font-light tracking-wider flex flex-col
                ${isDark ? 'text-gray-50' : 'text-gray-950'}`}>
                <span className="mb-4 sm:mb-6">
                  A network designed for the next era of navigation and discovery.
                </span>
                <span className="font-medium">
                  For agents and allies alike.
                </span>
              </h1>
            </div>

            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              onClick={handleBeginDemo}
              className={`fixed sm:absolute bottom-16 sm:bottom-auto sm:top-[600px] left-1/2 transform -translate-x-1/2
                px-4 py-2 rounded-lg w-[calc(100%-2rem)] sm:w-auto sm:min-w-[200px]
                group relative overflow-hidden
                ${isDark 
                  ? 'bg-emerald-500/10 hover:bg-emerald-500/20 text-emerald-500 hover:text-emerald-400' 
                  : 'bg-blue-500/10 hover:bg-blue-500/20 text-blue-600 hover:text-blue-500'}
                transition-all duration-300 ease-in-out
                sm:backdrop-blur-sm`}
            >
              <span className="relative z-10 font-light text-base sm:text-lg">
                Begin the beacon demo
              </span>
              <div className={`absolute inset-0 -z-10 opacity-0 group-hover:opacity-100
                transition-opacity duration-300 pointer-events-none
                ${isDark 
                  ? 'bg-gradient-to-r from-emerald-500/5 via-emerald-500/10 to-emerald-500/5' 
                  : 'bg-gradient-to-r from-blue-500/5 via-blue-500/10 to-blue-500/5'}`}
              />
            </motion.button>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HomePage;
