import { useState } from 'react';
import { Copy, Check } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark, oneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';

interface CodeBlockProps {
  code: string;
  language?: string;
  showLineNumbers?: boolean;
  isDark?: boolean;
  isLoading?: boolean;
}

export function CodeBlock({ code, language = 'text', showLineNumbers = true, isDark = false, isLoading = false }: CodeBlockProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const glowColor = isDark 
    ? 'rgba(16, 185, 129, 0.2)' 
    : 'rgba(37, 99, 235, 0.2)';

  const activeGlowColor = isDark 
    ? 'rgba(16, 185, 129, 0.4)' 
    : 'rgba(37, 99, 235, 0.4)';

  return (
    <div 
      className={`relative rounded-lg p-2 sm:p-4 ${
        isDark ? 'bg-gray-900' : 'bg-white border border-gray-200'
      }`}
      style={{
        boxShadow: isLoading 
          ? `0 0 15px ${glowColor}, 0 0 25px ${activeGlowColor}, 0 0 15px ${glowColor}`
          : `0 0 15px ${glowColor}`
      }}
    >
      <button
        onClick={copyToClipboard}
        className={`absolute right-2 sm:right-8 top-4 z-10 p-1.5 sm:p-2 rounded-lg ${
          isDark 
            ? 'text-emerald-500 hover:text-emerald-400 hover:bg-emerald-500/10' 
            : 'text-blue-600 hover:text-blue-500 hover:bg-blue-500/10'
        }`}
        aria-label="Copy code"
      >
        {copied ? <Check size={16} className="sm:w-5 sm:h-5" /> : <Copy size={16} className="sm:w-5 sm:h-5" />}
      </button>
      
      <div 
        className="w-full max-w-full overflow-x-auto pr-4"
        style={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: isDark ? '#4B5563 transparent' : '#94A3B8 transparent',
          maxHeight: '584px',
          minHeight: '40px'
        }}
      >
        <style>{`
          .code-container::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
          
          .code-container::-webkit-scrollbar-track {
            background: transparent;
          }
          
          .code-container::-webkit-scrollbar-thumb {
            background-color: ${isDark ? '#4B5563' : '#94A3B8'};
            border-radius: 20px;
            border: 2px solid transparent;
            background-clip: padding-box;
          }
          
          .code-container::-webkit-scrollbar-thumb:hover {
            background-color: ${isDark ? '#6B7280' : '#64748B'};
          }
          
          /* Only show scrollbar on hover */
          .code-container {
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;
          }
          
          .code-container:hover {
            scrollbar-color: ${isDark ? '#4B5563 transparent' : '#94A3B8 transparent'};
          }
        `}</style>

        <SyntaxHighlighter
          language="json"
          style={isDark ? atomDark : oneLight}
          customStyle={{
            background: 'transparent',
            padding: 0,
            margin: 0,
            width: '100%',
            maxWidth: '100%',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
            paddingRight: window.innerWidth >= 640 ? '2.5rem' : '1.5rem',
            fontSize: window.innerWidth >= 640 ? '0.875rem' : '0.75rem'
          }}
          wrapLines={true}
          wrapLongLines={true}
          lineProps={{
            style: {
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap'
            }
          }}
          className="w-full code-container text-xs sm:text-sm"
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}
