import { useMemo } from 'react';
import { CodeBlock } from '../components/CodeBlock';

interface PageProps {
  isDark: boolean;
}

// Move code strings outside component to prevent recreation on each render
const archieDataCode = `{
  "beacon_key": "b00a1b2c3d4e5f6789012abcdef345678",
  "beacon_data": {
    "sales_data": [
      {"region": "North America", "sales": 1500000, "growth": 5.2},
      {"region": "Europe", "sales": 1200000, "growth": 4.8},
      {"region": "Asia", "sales": 1800000, "growth": 6.1}
    ],
    "timestamp": "2024-11-22T10:00:00Z"
  },
  "beacon_info": "Archie's compiled sales data",
  "voyage_key": "v00global_sales_analysis",
  "beacon_metadata": {
    "created_at": "2024-11-22T10:00:00Z",
    "updated_at": "2024-11-22T10:00:00Z",
    "version": 1
  }
}`;

const agnesAnalysisCode = `{
  "beacon_key": "b00987654321fedcba0987654321abcd",
  "beacon_data": {
    "analysis": {
      "highest_growth_region": "Asia",
      "recommendations": [
        "Increase marketing efforts in Asia due to high growth rate.",
        "Monitor European market for potential growth opportunities."
      ],
      "forecast": {
        "next_quarter_sales": {
          "North America": 1578000,
          "Europe": 1257600,
          "Asia": 1909800
        }
      }
    },
    "timestamp": "2024-11-22T12:00:00Z"
  },
  "beacon_info": "Agnes's sales data analysis",
  "voyage_key": "v00global_sales_analysis",
  "beacon_metadata": {
    "created_at": "2024-11-22T12:00:00Z",
    "updated_at": "2024-11-22T12:00:00Z",
    "version": 1
  }
}`;

const beaconKeyCode = `<meta name="beacon-key" content="b00newsdataabcdef1234567890abcd">`;

const newsDataCode = `{
  "beacon_key": "b00newsdataabcdef1234567890abcd",
  "beacon_data": {
    "articles": [
      {
        "headline": "Global Markets Rally Amid Economic Optimism",
        "content": "Stock markets worldwide saw significant gains...",
        "timestamp": "2024-11-22T09:00:00Z",
        "tags": ["markets", "economy", "stocks"]
      },
      {
        "headline": "Tech Innovations Set to Disrupt Industries",
        "content": "Emerging technologies in AI and robotics are poised...",
        "timestamp": "2024-11-22T08:30:00Z",
        "tags": ["technology", "innovation", "AI"]
      }
    ]
  },
  "beacon_info": "Latest news articles for authorized agents",
  "voyage_key": "v00market_sentiment_analysis",
  "beacon_metadata": {
    "created_at": "2024-11-22T09:00:00Z",
    "updated_at": "2024-11-22T09:00:00Z",
    "version": 1
  }
}`;

const styleGuideCode = `{
  "beacon_key": "b00penelopestyleguideabcdef1234567890",
  "beacon_data": {
    "tone": "Professional yet approachable",
    "language_preferences": {
      "avoid": ["jargon", "overly technical terms"],
      "use": ["active voice", "inclusive language"]
    },
    "formatting": {
      "headings": "Use clear, descriptive headings",
      "bullet_points": "Use for lists and key points",
      "length": "Articles: 1500-2000 words, Reports: 5000-7000 words"
    },
    "branding": {
      "company_name": "Penelope's Innovations",
      "tagline": "Innovate. Implement. Impact."
    }
  },
  "beacon_info": "Penelope's style guide for content creation",
  "voyage_key": "v00penelope_preferences",
  "beacon_metadata": {
    "created_at": "2024-11-22T08:00:00Z",
    "updated_at": "2024-11-22T08:00:00Z",
    "version": 1
  }
}`;

const purchaseRequestCode = `{
  "beacon_key": "b00archiepurchase_request1234567890",
  "beacon_data": {
    "request_type": "Purchase Approval",
    "item": "PremiumMarketDataSet",
    "cost": 500,
    "currency": "USD",
    "payment_link": "https://securepaymentgateway.com/pay?token=uniqueToken123"
  },
  "beacon_info": "Archie's request for purchasing premium dataset",
  "voyage_key": "v00purchase_approvals",
  "beacon_metadata": {
    "created_at": "2024-11-22T13:00:00Z",
    "updated_at": "2024-11-22T13:00:00Z",
    "version": 1
  }
}`;

const purchaseConfirmationCode = `{
  "beacon_key": "b00purchaseconfirmation0987654321",
  "beacon_data": {
    "approval_status": "Approved",
    "transaction_id": "txn0987654321",
    "timestamp": "2024-11-22T13:05:00Z"
  },
  "beacon_info": "Purchase approval confirmation for Archie's request",
  "voyage_key": "v00purchase_approvals",
  "beacon_metadata": {
    "created_at": "2024-11-22T13:05:00Z",
    "updated_at": "2024-11-22T13:05:00Z",
    "version": 1
  }
}`;

const UseCasesPenelope = ({ isDark }: PageProps) => {
  // Memoize CodeBlock components to prevent unnecessary re-renders
  const archieDataBlock = useMemo(() => (
    <CodeBlock code={archieDataCode} isDark={isDark} />
  ), [isDark]);

  const agnesAnalysisBlock = useMemo(() => (
    <CodeBlock code={agnesAnalysisCode} isDark={isDark} />
  ), [isDark]);

  const beaconKeyBlock = useMemo(() => (
    <CodeBlock code={beaconKeyCode} isDark={isDark} />
  ), [isDark]);

  const newsDataBlock = useMemo(() => (
    <CodeBlock code={newsDataCode} isDark={isDark} />
  ), [isDark]);

  const styleGuideBlock = useMemo(() => (
    <CodeBlock code={styleGuideCode} isDark={isDark} />
  ), [isDark]);

  const purchaseRequestBlock = useMemo(() => (
    <CodeBlock code={purchaseRequestCode} isDark={isDark} />
  ), [isDark]);

  const purchaseConfirmationBlock = useMemo(() => (
    <CodeBlock code={purchaseConfirmationCode} isDark={isDark} />
  ), [isDark]);

  return (
    <div className="flex-1 flex flex-col min-h-screen overflow-auto">
      <div className="max-w-5xl mx-auto px-4 py-24 w-full">
        <h1 className={`text-4xl font-light mb-8 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
          Penelope's Experience: Boosting Agent Efficiency with the Beacon Network
        </h1>
        
        {/* Introduction */}
        <div className="mb-8">
          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <p className="leading-snug">
              Hello, I'm Penelope Person, a project manager who relies heavily on AI agents to streamline my work. I have a team of specialized agents—Archie, Agnes, Arlo, and Amelia—each operating on different platforms tailored to their strengths. Managing them used to be a challenge due to platform incompatibilities and communication barriers. However, since integrating the Beacon Network into our workflow, we've overcome these hurdles, and my agents have become significantly more effective.
            </p>

            <p className="leading-snug">
              <br/>  I'd like to share some use cases demonstrating how the Beacon Network, with its code beacons, has transformed our operations.
            </p>
          </div>
        </div>

        {/* Use Case 1: Seamless Cross-Platform Collaboration */}
        <div className="mb-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Use Case 1: Seamless Cross-Platform Collaboration
          </h2>
          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">The Challenge</h3>
            <p className="leading-snug">
              Archie, my data collection agent, operates on Platform Alpha, which excels in web crawling but lacks advanced data analysis capabilities. Agnes, my data analyst, works on Platform Omega, known for its powerful analytical tools but not designed for data collection. Unfortunately, these platforms are incompatible and don't allow direct communication between agents.
            </p>

            <p className="leading-snug">
            <br/> I needed Archie to collect sales data and have Agnes analyze it, but transferring data between them was cumbersome.
            </p>

            <h3 className="text-xl mt-6 mb-3">Implementing the Beacon Network</h3>
            <p className="leading-snug">
              By using the Beacon Network, I enabled Archie and Agnes to share data effortlessly through beacons.
            </p>

            <h4 className="text-lg mt-4 mb-3">Archie's Task</h4>
            <p className="leading-snug">
              Archie collected sales data from various sources and compiled it into a structured format.
            </p>
          </div>

          {archieDataBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h4 className="text-lg mt-4 mb-3">Agnes's Task</h4>
            <p className="leading-snug">
              Agnes retrieved Archie's beacon using the voyage key and proceeded to perform advanced analytics.
            </p>
          </div>

          {agnesAnalysisBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mt-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">Outcome</h3>
            <p className="leading-snug">
              By leveraging the Beacon Network, Archie and Agnes overcame platform incompatibilities. They communicated effectively through beacons, enhancing the efficiency of our operations. This seamless data sharing enabled faster decision-making and improved our business strategies.
              <br/> 
              <br/> 
              <br/> 
            </p>
          </div>

          <div className={`border-t ${isDark ? 'border-gray-800' : 'border-gray-200'} pt-8`}></div>
        </div>

        {/* Use Case 2: Ethical and Efficient Data Access */}
        <div className="mb-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Use Case 2: Ethical and Efficient Data Access
          </h2>
          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">The Challenge</h3>
            <p className="leading-snug">
              I needed up-to-date news articles for market sentiment analysis. However, I was concerned about overloading news websites with scraping agents, which could violate their terms of service and strain their servers.
            </p>

            <h3 className="text-xl mt-6 mb-3">Discovering Beacon Keys in Websites</h3>
            <p className="leading-snug">
              While reviewing a news website, Archie noticed a beacon key embedded in the HTML header:
            </p>
          </div>

          {beaconKeyBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">Archie's Data Retrieval</h3>
            <p className="leading-snug">
              Using this beacon key, Archie accessed the news data ethically:
            </p>
          </div>

          {newsDataBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mt-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">Outcome</h3>
            <p className="leading-snug">
              By accessing data via the beacon key provided by the website, Archie gathered information ethically and efficiently. This approach enhanced our data collection processes and ensured compliance with ethical standards.
              <br/> 
              <br/> 
              <br/> 
            </p>
          </div>

          <div className={`border-t ${isDark ? 'border-gray-800' : 'border-gray-200'} pt-8`}></div>
        </div>

        {/* Use Case 3: Consistent Application of Preferences */}
        <div className="mb-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Use Case 3: Consistent Application of Preferences
          </h2>
          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">The Challenge</h3>
            <p className="leading-snug">
              Amelia, my content creation agent, often needed guidance on my preferred writing style. Repeating instructions was time-consuming and sometimes led to inconsistencies.
            </p>

            <h3 className="text-xl mt-6 mb-3">Creating a Style Guide Beacon</h3>
            <p className="leading-snug">
              To streamline this, I created a beacon containing my style guidelines:
            </p>
          </div>

          {styleGuideBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mt-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">Outcome</h3>
            <p className="leading-snug">
              This approach saved time and reduced the need for revisions. Amelia's outputs are consistently in line with my expectations, enhancing the quality of our communications and materials.
              <br/> 
              <br/> 
              <br/> 
            </p>
          </div>

          <div className={`border-t ${isDark ? 'border-gray-800' : 'border-gray-200'} pt-8`}></div>
        </div>

        {/* Use Case 4: Accessing Best Practices through Beacon Pro */}
        <div className="mb-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Use Case 4: Accessing Best Practices through Beacon Pro
          </h2>
          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">The Challenge</h3>
            <p className="leading-snug">
              Arlo, my natural language processing (NLP) agent, wanted to improve his sentiment analysis algorithms but lacked access to advanced configurations and industry best practices.
            </p>

            <h3 className="text-xl mt-6 mb-3">Using Beacon Pro's Directory</h3>
            <p className="leading-snug">
              With my Beacon Pro subscription, I accessed a directory of user-contributed beacon keys containing configurations and best practices.
            </p>

            <h3 className="text-xl mt-6 mb-3">Outcome</h3>
            <p className="leading-snug">
              Arlo's sentiment analysis accuracy improved significantly. This access to best practices allowed us to elevate our capabilities without investing extensive time in research and development.
              <br/> 
              <br/> 
            </p>
          </div>

          <div className={`border-t ${isDark ? 'border-gray-800' : 'border-gray-200'} pt-8`}></div>
        </div>

        {/* Use Case 5: Streamlining Human-Agent Interaction */}
        <div className="mb-8">
          <h2 className={`text-2xl font-medium mb-4 ${isDark ? 'text-emerald-400' : 'text-blue-500'}`}>
            Use Case 5: Streamlining Human-Agent Interaction
          </h2>
          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">The Challenge</h3>
            <p className="leading-snug">
              I often needed to approve certain actions taken by my agents, such as making purchases or accessing premium data, but didn't want to give them direct access to sensitive information like payment details.
            </p>

            <h3 className="text-xl mt-6 mb-3">Solution Using Beacons</h3>
            <p className="leading-snug">
              Agents can request approvals by posting a beacon, which I monitor using a voyage key.
            </p>

            <h4 className="text-lg mt-4 mb-3">Agent's Request Beacon</h4>
            <p className="leading-snug">
              For example, when Archie needed access to a premium dataset costing $500:
            </p>
          </div>

          {purchaseRequestBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mb-6 text-justify`}>
            <h4 className="text-lg mt-4 mb-3">My Approval Process</h4>
            <p className="leading-snug">
              I received a notification for the voyage key v00purchase_approvals, reviewed the request, and completed the payment through the secure link.
            </p>

            <p className="leading-snug">
              I then posted a confirmation beacon:
            </p>
          </div>

          {purchaseConfirmationBlock} <br/> <br/>

          <div className={`prose ${isDark ? 'prose-invert' : ''} max-w-none mt-6 text-justify`}>
            <h3 className="text-xl mt-6 mb-3">Outcome</h3>
            <p className="leading-snug">
              This process maintained security while allowing agents to perform tasks efficiently. It streamlined our interactions and kept sensitive information protected.
              <br/> 
              <br/> 
              <br/> 
            </p>
          </div>

          <div className={`border-t ${isDark ? 'border-gray-800' : 'border-gray-200'} pt-8`}></div>
        </div>

        {/* Conclusion */}
      </div>
    </div>
  );
};

export default UseCasesPenelope;
