import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Copy, Clipboard, Check, KeyRound, ArrowRight, ChevronRight, ChevronLeft, X } from 'lucide-react';
import { CodeBlock } from '../components/CodeBlock';
import { Beacon, BeaconVoyage } from '../services/api3';
import { LighthouseIcon } from '../components/LighthouseIcon';
import { useAuth } from '../context/AuthContext';

interface TerminalPageProps {
  isDark: boolean;
}

type Page = 'one' | 'two' | 'three' | 'create' | 'success' | 'retrieve' | 'voyage' | 'example' | 'premium';

const pages: Page[] = ['one', 'example', 'two', 'three', 'create', 'success', 'retrieve', 'voyage', 'premium'];

export default function TerminalPage({ isDark }: TerminalPageProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  
  // Core state
  const [currentPage, setCurrentPage] = useState<Page>('one');
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isReady, setIsReady] = useState(false);

  // Create beacon state
  const [input, setInput] = useState('');
  const [createResponse, setCreateResponse] = useState<string | null>(null);

  // Retrieve beacon state
  const [pasteKey, setPasteKey] = useState('');
  const [retrieveResponse, setRetrieveResponse] = useState<string | null>(null);

  // Voyage state
  // const [voyageData, setVoyageData] = useState('');
  const [voyageKey, setVoyageKey] = useState('v0077c46345a17906f88e0634f79d29224d');
  // const [voyageBeaconInfo, setVoyageBeaconInfo] = useState('');
  const [voyageResponse, setVoyageResponse] = useState<string | null>(null);

  // Add refs for each section
  const containerRef = useRef<HTMLDivElement>(null);
  // const welcomeRef = useRef<HTMLDivElement>(null);
  // const aboutRef = useRef<HTMLDivElement>(null);
  // const featuresRef = useRef<HTMLDivElement>(null);
  const gettingStartedRef = useRef<HTMLDivElement>(null);
  const createRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  const retrieveRef = useRef<HTMLDivElement>(null);
  const voyageRef = useRef<HTMLDivElement>(null);

  // Track whether sections are unlocked
  const [unlockedSections, setUnlockedSections] = useState<Record<string, boolean>>({
    one: true,
    example: true,
    two: true,
    three: true,
    create: true,
    success: false,
    retrieve: false,
    voyage: false,
    premium: false
  });

  // Add currentIndex calculation
  const currentIndex = pages.indexOf(currentPage);

  // First add a new state for beacon info
  const [beaconInfo, setBeaconInfo] = useState('');

  useEffect(() => {
    Promise.all([
      new Promise(resolve => setTimeout(resolve, 500))
    ]).then(() => {
      setIsReady(true);
    });
  }, []);

  useEffect(() => {
    if (createResponse) {
      setUnlockedSections(prev => ({ ...prev, success: true }));
    }
  }, [createResponse]);

  useEffect(() => {
    if (copied) {
      setUnlockedSections(prev => ({ ...prev, retrieve: true }));
    }
  }, [copied]);

  useEffect(() => {
    if (retrieveResponse) {
      setUnlockedSections(prev => ({ ...prev, voyage: true }));
    }
  }, [retrieveResponse]);

  useEffect(() => {
    if (voyageResponse) {
      setUnlockedSections(prev => ({ ...prev, premium: true }));
    }
  }, [voyageResponse]);

  const handleExit = () => {
    navigate('/');
  };

  if (!isReady) {
    return null;
  }

  const handleBeaconCreate = async () => {
    if (input.trim()) {
      setLoading(true);
      try {
        const beacon = new Beacon({
          beacon_data: input.trim(),
          beacon_info: beaconInfo.trim() || undefined,
        });
        await beacon.create();
        setCreateResponse(JSON.stringify(beacon.all, null, 2));
        setInput('');
        setBeaconInfo(''); // Clear beacon info
        setCurrentPage('success');
      } catch (error) {
        setCreateResponse(JSON.stringify({ 
          error: 'Failed to create beacon',
          details: error instanceof Error ? error.message : 'Unknown error'
        }, null, 2));
      }
      setLoading(false);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleBeaconCreate();
    }
  };

  const handleCopyKey = () => {
    if (createResponse) {
      const data = JSON.parse(createResponse);
      navigator.clipboard.writeText(data.beacon_key);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
        setCurrentPage('retrieve');
      }, 1000);
    }
  };

  const handlePasteKey = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setPasteKey(clipboardText);
      await handleBeaconGet(clipboardText);
    } catch (error) {
      console.error('Failed to paste:', error);
    }
  };

  const handleBeaconGet = async (key: string = pasteKey) => {
    if (!key.trim()) return;

    setLoading(true);
    try {
      const beacon = new Beacon(undefined, key.trim());
      await beacon.refresh();
      setRetrieveResponse(JSON.stringify(beacon.all, null, 2));
    } catch (error) {
      setRetrieveResponse(JSON.stringify({ 
        error: 'Failed to refresh beacon',
        details: error instanceof Error ? error.message : 'Unknown error'
      }, null, 2));
    }
    setLoading(false);
  };

  // const handleVoyageBeaconCreate = async () => {
  //   if (voyageData.trim() && voyageKey.trim()) {
  //     setLoading(true);
  //     try {
  //       const beacon = new Beacon({
  //         data: voyageData.trim(),
  //         beacon_info: voyageBeaconInfo.trim()
  //       });
  //       await beacon.createWithVoyage(voyageKey.trim());
  //       setVoyageResponse(JSON.stringify(beacon.all, null, 2));
  //       setVoyageData('');
  //       setVoyageKey('');
  //       setVoyageBeaconInfo('');
  //     } catch (error) {
  //       setVoyageResponse(JSON.stringify({ 
  //         error: 'Failed to create beacon with voyage',
  //         details: error instanceof Error ? error.message : 'Unknown error'
  //       }, null, 2));
  //     }
  //     setLoading(false);
  //   }
  // };

  const handleAuthFlow = () => {
    if (user) {
      navigate('/profile');
    } else {
      navigate('/auth');
    }
  };

  const handleVoyageSearch = async () => {
    if (!voyageKey.trim()) return;

    setLoading(true);
    try {
      const voyage = new BeaconVoyage(voyageKey.trim());
      await voyage.refresh();
      
      // Get all beacons in the voyage
      const beacons = await voyage.getBeacons();
      
      // Format the response to include voyage data and beacon details
      const formattedResponse = {
        voyage_key: voyage.all.voyage_key,
        beacon_count: voyage.all.beacon_keys.length,
        beacons: beacons.map(beacon => ({
          beacon: beacon.all
        }))
      };
      
      setVoyageResponse(JSON.stringify(formattedResponse, null, 2));
      setUnlockedSections(prev => ({ ...prev, premium: true }));
    } catch (error) {
      setVoyageResponse(JSON.stringify({ 
        error: 'Failed to find voyage',
        details: error instanceof Error ? error.message : 'Unknown error'
      }, null, 2));
    }
    setLoading(false);
  };

  return (
    <div className="flex-1 flex flex-col min-h-screen overflow-auto">
      <div className="w-full max-w-5xl mx-auto px-4 pb-2 sm:pb-12 pt-24">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 gap-4">
          <div className="flex items-center">
            <LighthouseIcon className={`${isDark ? 'text-emerald-500' : 'text-blue-600'}`} size={48} />
            <h1 className="text-2xl font-light pl-2">Introducing Beacon</h1>
          </div>

          <div className="flex items-center gap-2 sm:gap-3">
            <button
              onClick={handleExit}
              className={`p-2.5 rounded-lg transition-colors duration-200 w-11 h-11 flex items-center justify-center
                ${isDark 
                  ? 'bg-gray-800/80 text-gray-400 hover:bg-gray-700/80' 
                  : 'bg-gray-100/80 text-gray-600 hover:bg-gray-200/80'}`}
            >
              <X size={18} />
            </button>
            <button
              onClick={() => setCurrentPage(pages[currentIndex - 1])}
              disabled={currentIndex === 0 || !unlockedSections[pages[currentIndex - 1]]}
              className={`p-2.5 rounded-lg transition-colors duration-200 w-11 h-11 flex items-center justify-center
                ${(currentIndex === 0 || !unlockedSections[pages[currentIndex - 1]])
                  ? 'opacity-50 cursor-not-allowed' 
                  : isDark 
                    ? 'bg-emerald-500/20 text-emerald-500 hover:bg-emerald-500/30' 
                    : 'bg-blue-500/20 text-blue-600 hover:bg-blue-500/30'}`}
            >
              <ChevronLeft size={22} />
            </button>
            <button
              onClick={() => setCurrentPage(pages[currentIndex + 1])}
              disabled={currentIndex === pages.length - 1 || !unlockedSections[pages[currentIndex + 1]]}
              className={`p-2.5 rounded-lg transition-colors duration-200 w-11 h-11 flex items-center justify-center
                ${(currentIndex === pages.length - 1 || !unlockedSections[pages[currentIndex + 1]])
                  ? 'opacity-50 cursor-not-allowed' 
                  : isDark 
                    ? 'bg-emerald-500/20 text-emerald-500 hover:bg-emerald-500/30' 
                    : 'bg-blue-500/20 text-blue-600 hover:bg-blue-500/30'}`}
            >
              <ChevronRight size={22} />
            </button>
          </div>
        </div>

        <div className="terminal-container">
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className={`overflow-hidden ${isDark ? 'bg-transparent' : 'bg-transparent'}`}
            >
              <div ref={containerRef} className="terminal-content px-2 sm:px-4 py-4 sm:py-6 space-y-6 sm:space-y-8">
                {/* Welcome Section */}
                {currentPage === 'one' && (
                  <div ref={gettingStartedRef} className="space-y-4">
                    <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      What is a beacon?
                    </h2>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      A beacon is a lightweight data object that provides global context to AI systems. Beacons are stored on the beacon network. Any beacon is accessible if you have the beacon key.
                    </p>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                      Every beacon has:
                    </p>
                    <ul className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light space-y-3 list-disc pl-4 sm:pl-6 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Key</strong>: This is what makes each beacon unique. An identifier used to access and retrieve your beacon on the network. Any beacon on the network is retrievable if if you have the beacon key.</li>
                      <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Data</strong>: The main content or information within the beacon. All text formats are supported.</li>
                      <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Info</strong>: A short description of the beacon's contents, like a title, subject, or summary</li>
                      <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Voyage</strong>: A voyage is like a beacon chain or project tag. It's a sequence of beacons that are linked together. The beacon's voyage will have its own key that helps link it to other beacons on the voyage. More on this later. </li>
                      <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Metadata</strong>: Tracks when the beacon was created and updated.</li>
                    </ul>
                  </div>
                )}

                {currentPage === 'example' && (
                  <div ref={gettingStartedRef} className="space-y-4">
                    <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      What does a beacon look like?
                    </h2>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      Here's an example of a beacon that contains tech news. If you're not a developer or an AI agent, don't worry if this looks foreign to you.
                    </p>
                    <CodeBlock
                      code={`{
  "beacon_key": "b00295183474d92606bfb59a48d46b590ae",
  "beacon_data": {
    "tech_news": [
      {
        "headline": "Apple's Latest Innovation Raises the Bar for AI in Smartphones",
        "summary": "Apple has unveiled its latest AI-powered smartphone feature, setting a new industry standard. The update integrates cutting-edge machine learning for improved user experience, hinting at Apple's deeper push into AI technologies. Analysts suggest this move could redefine the competitive landscape for mobile devices."
      },
      {
        "headline": "Google's Cloud AI Suite Gains Traction Among Enterprise Clients",
        "summary": "Google's Cloud AI Suite is capturing the attention of enterprise users, boasting new updates that promise faster analytics and better data security. The tech giant's investments in generative AI are now paying off, as more companies adopt its robust cloud solutions for critical operations."
      },
      {
        "headline": "Amazon Expands Robotics Division to Drive E-commerce Efficiency",
        "summary": "Amazon is doubling down on its robotics division, introducing advanced systems to optimize warehouse operations. These innovations aim to meet increasing demand and reduce fulfillment times, signaling a major shift in how Amazon approaches logistics."
      }
    ]
  },
  "beacon_info": "bestTech.com - Afternoon Digest Nov 22, 2024",
  "voyage": {
    "voyage_key": "v00516905de5dca793989fd8e8e0a621a9f",
    "voyage_open": false
  },
  "beacon_metadata": {
    "created_at": "2024-12-02T02:32:57.560902+00:00Z",
    "updated_at": "2024-12-02T02:32:57.560902+00:00Z"
  }
}`}
                      showLineNumbers={false}
                      isDark={isDark}
                      language="json"
                    />
                    <div className="space-y-4 mt-6">
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-semibold px-1 pt-4 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                        As a reminder, every beacon has:
                      </p>
                      <ul className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light space-y-3 list-disc pl-4 sm:pl-6 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Key</strong>: This is what makes each beacon unique. An identifier used to access and retrieve your beacon on the network. Any beacon on the network is retrievable if if you have the beacon key.</li>
                        <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Data</strong>: The main content or information within the beacon. All text formats are supported.</li>
                        <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Info</strong>: A short description of the beacon's contents, like a title, subject, or summary</li>
                        <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Voyage</strong>: A voyage is like a beacon chain or project tag. It's a sequence of beacons that are linked together. The beacon's voyage will have its own key that helps link it to other beacons on the voyage. More on this later. </li>
                        <li className="pl-2 leading-[1.8] sm:leading-relaxed"><strong>Beacon Metadata</strong>: Tracks when the beacon was created and updated.</li>
                      </ul>
                    </div>
                  </div>
                )}

                {currentPage === 'two' && (
                  <div ref={gettingStartedRef} className="space-y-6">
                    <div className="space-y-4">
                      <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        What does a beacon do?
                      </h2>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        A beacon provides relevant context to AI systems, serving as a simple yet flexible tool designed for easy use by both AI's and humans. Because of their flexibility, beacons can be used in a wide variety of applications.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Creation and Retrieval
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Beacons can be created or retrieved by both AI's and humans. This dual capability ensures seamless communication and context-sharing between all forms of intelligence.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Enhancing AI Context
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        When an AI retrieves a beacon, it gains access to the beacon's data. This data provides important context to the AI which helps it better understand its objectives or environment.
                      </p>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Humans can also manually provide beacon data to their AI's, enhancing the AI's understanding and performance.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Facilitating Collaboration
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        AI's and humans both have the ability to create beacons, allowing easy collaboration across intellegences. AI's can use beacons to provide context to other AI's, and humans can use beacons to provide context to their AI's, or someone else's AI. This fosters a collaborative environment where information flows freely.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Global Accessibility
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Beacons are global objects, meaning they can carry context across different AI platforms. This universality ensures that beacon-poweredcontext is maintained and accessible, regardless of the specific AI platforms involved.
                      </p>
                    </div>
                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Sharing Beacons
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Beacons are meant to be shared by passing beacon keys. Any form of intelligence can retrieve a beacon. But only if they have the beacon key.
                      </p>
                    </div>
                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Open Source Principles
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Beacons are built with open source principles. We encourage developers to build on top of the beacon network and contribute to its growth. All of our source code is available on <a href="https://github.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">GitHub</a>.
                      </p>
                    </div>
                  </div>
                )}

                {currentPage === 'three' && (
                  <div ref={gettingStartedRef} className="space-y-6">
                    <div className="space-y-4">
                      <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Think of beacons as the GPS for AI.
                      </h2>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        We like to think of beacons as the GPS for AI.
                      </p>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Just as GPS guides us through geopositional space, beacons guide AI's through informational space.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        GPS Basics
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        GPS relies on a constellation of satellites orbiting Earth, forming a global network accessible from anywhere. When your device connects to the GPS network, it receives a navigation message—a lightweight data package containing precise space and time information. This minimal yet crucial data enables your device to determine its location.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Beacons Basics
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Beacons operate on a worldwide network accessible by any AI system. When an AI sends a beacon key to the network, it receives back a beacon—a compact data object containing essential contextual information. This contextual data enhances the AI's understanding of its environment, leading to improved performance and more accurate results.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <h3 className={`text-lg sm:text-xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Key Parallels
                      </h3>
                      <ul className={`space-y-3 text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        <li>
                          <span className="font-medium">Universal Access:</span> Both systems are globally accessible, regardless of platform or device.
                        </li>
                        <li>
                          <span className="font-medium">Efficient Data Exchange:</span> Both use minimal data transfer for maximum impact.
                        </li>
                        <li>
                          <span className="font-medium">Real-time Performance:</span> Both provide immediate, valuable information to guide decision-making.
                        </li>
                        <li>
                          <span className="font-medium">Open Infrastructure:</span> Both systems foster innovation through their open nature and flexibility.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {/* Create Section */}
                {currentPage === 'create' && (
                  <div ref={createRef} className="space-y-4 sm:space-y-6">
                    <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      Create Your First Beacon
                    </h2>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      It's time to create your first beacon! Enter your beacon data below. You can also add an optional description to help identify your beacon later. <br /><br />
                      Enter anything you want. Or just smash the keyboard and see what happens.
                    </p>
                    <div className="space-y-3 sm:space-y-4">
                      <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter your beacon data..."
                        className={`w-full rounded-lg px-4 py-3 text-sm
                          transition-all duration-300 focus:outline-none
                          ${isDark 
                            ? 'bg-gray-900/50 border-gray-800 focus:border-emerald-500/50 text-gray-100' 
                            : 'bg-white border-gray-200 focus:border-blue-500/50 text-gray-900'}
                          border focus:ring-2 focus:ring-opacity-50
                          ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`}
                      />
                      <input
                        type="text"
                        value={beaconInfo}
                        onChange={(e) => setBeaconInfo(e.target.value)}
                        placeholder="Enter beacon info (optional)..."
                        className={`w-full rounded-lg px-4 py-3 text-sm
                          transition-all duration-300 focus:outline-none
                          ${isDark 
                            ? 'bg-gray-900/50 border-gray-800 focus:border-emerald-500/50 text-gray-100' 
                            : 'bg-white border-gray-200 focus:border-blue-500/50 text-gray-900'}
                          border focus:ring-2 focus:ring-opacity-50
                          ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`}
                      />
                      <button
                        onClick={handleBeaconCreate}
                        disabled={loading || !input.trim()}
                        className={`px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                          ${isDark 
                            ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                            : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                          transition-colors duration-200 text-sm`}
                      >
                        {loading ? 'Creating...' : 'Create Beacon'}
                      </button>
                    </div>
                  </div>
                )}

                {/* Success Section */}
                {currentPage === 'success' && createResponse && (
                  <div ref={successRef} className="space-y-4 sm:space-y-6">
                    <h3 className={`text-xl sm:text-2xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      Beacon Created Successfully!
                    </h3>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      That was easy. Press the button below to copy your beacon key and continue.
                    </p>
                    <div className="space-y-4">
                      <CodeBlock
                        code={createResponse}
                        showLineNumbers={false}
                        isDark={isDark}
                        language="json"
                      />
                      <button
                        onClick={handleCopyKey}
                        className={`px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                          ${isDark 
                            ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                            : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                          transition-colors duration-200 text-sm`}
                      >
                        {copied ? (
                          <>
                            <Check size={16} />
                            <span>Copied!</span>
                          </>
                        ) : (
                          <>
                            <Copy size={16} />
                            <span>Copy Beacon Key to Continue</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}

                {/* Retrieve Section */}
                {currentPage === 'retrieve' && (
                  <div ref={retrieveRef} className="space-y-4 sm:space-y-6">
                    <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      Retrieve Your Beacon
                    </h2>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      Now that you've created a beacon, let's retrieve it. Click the button below to paste your beacon key and retrieve the beacon.
                    </p>
                    <div className="space-y-4">
                      <input
                        type="text"
                        value={pasteKey}
                        onChange={(e) => setPasteKey(e.target.value)}
                        placeholder="Enter beacon key..."
                        className={`w-full rounded-lg px-4 py-3 text-sm
                          transition-all duration-300 focus:outline-none
                          ${isDark 
                            ? 'bg-gray-900/50 border-gray-800 focus:border-emerald-500/50 text-gray-100' 
                            : 'bg-white border-gray-200 focus:border-blue-500/50 text-gray-900'}
                          border focus:ring-2 focus:ring-opacity-50
                          ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`}
                      />
                      <button
                        onClick={() => handlePasteKey()}
                        disabled={loading}
                        className={`px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                          ${isDark 
                            ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                            : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                          transition-colors duration-200 text-sm`}
                      >
                        {loading ? 'Retrieving...' : 'Paste Key and Get Beacon'}
                      </button>
                      {retrieveResponse && (
                        <>
                          <div className="space-y-2">
                            <p className={`text-base sm:text-lg leading-relaxed font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                              Congrats again! You've successfully retrieved your beacon.
                            </p>
                            <p className={`text-sm sm:text-base leading-relaxed font-light ${isDark ? 'text-gray-300' : 'text-black'}`}>
                              Notice how the beacon contains all the data you stored, along with metadata about when it was created and last updated. <br /><br />
                              Tags on your beacon's voyage were also automatically added.
                            </p>
                          </div>
                          <CodeBlock
                            code={retrieveResponse}
                            showLineNumbers={false}
                            isDark={isDark}
                            language="json"
                          />
                          <button
                            onClick={() => setCurrentPage('voyage')}
                            className={`px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                              ${isDark 
                                ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                                : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                              transition-colors duration-200 text-sm`}
                          >
                            <ArrowRight size={16} />
                            <span>Continue to learn about voyages</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {/* Voyage Section */}
                {currentPage === 'voyage' && (
                  <div ref={voyageRef} className="space-y-4 sm:space-y-6">
                    <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      Understanding Voyages
                    </h2>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      A voyage is a powerful way to link multiple beacons together. Think of it as a chain of beacons, where each beacon adds to the context or story. Voyages are perfect for tracking conversations, documenting processes, or building knowledge bases. <br /><br />
                      Voyages can either be open or closed. If a voyage is open, anyone can join a beacon to it, if its closed, only its creator can add beacons.
                    </p>

                    <div className="space-y-4">
                      <h3 className={`text-xl sm:text-2xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Find a Voyage
                      </h3>
                      <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        Let's explore an existing voyage. <br /><br />
                        We'll use a voyage key to find all the beacons on this voyage.
                      </p>
                      
                      <div className="space-y-4">
                        <input
                          type="text"
                          value={voyageKey}
                          onChange={(e) => setVoyageKey(e.target.value)}
                          placeholder="Enter voyage key..."
                          defaultValue="v0077c46345a17906f88e0634f79d29224d"
                          className={`w-full rounded-lg px-4 py-3 text-sm
                            transition-all duration-300 focus:outline-none
                            ${isDark 
                              ? 'bg-gray-900/50 border-gray-800 focus:border-emerald-500/50 text-gray-100' 
                              : 'bg-white border-gray-200 focus:border-blue-500/50 text-gray-900'}
                            border focus:ring-2 focus:ring-opacity-50
                            ${isDark ? 'focus:ring-emerald-500/20' : 'focus:ring-blue-500/20'}`}
                        />
                        <button
                          onClick={handleVoyageSearch}
                          disabled={loading}
                          className={`px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                            ${isDark 
                              ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                              : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                            transition-colors duration-200 text-sm`}
                        >
                          {loading ? 'Searching...' : 'Search Voyage'}
                        </button>

                        {voyageResponse && (
                          <div className="space-y-4">
                            <CodeBlock
                              code={voyageResponse}
                              showLineNumbers={false}
                              isDark={isDark}
                              language="json"
                            />
                            
                            <div className="space-y-4 mt-6">
                              <h3 className={`text-xl sm:text-2xl font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                                You're getting the hang of this!
                              </h3>
                              <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                                See how the beacons are linked together, creating a chain of context that can be followed from start to finish. <br /><br />
                                Beacons containing the dividional breakdown for each of the major sports leagues are linked together on this voyage.
                              </p>
                              
                              <button
                                onClick={() => setCurrentPage('premium')}
                                className={`px-4 py-2.5 rounded-lg w-full flex items-center justify-center gap-2
                                  ${isDark 
                                    ? 'bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-500' 
                                    : 'bg-blue-500/20 hover:bg-blue-500/30 text-blue-600'}
                                  transition-colors duration-200 text-sm`}
                              >
                                <ArrowRight size={16} />
                                <span>Continue!</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Premium Section */}
                {currentPage === 'premium' && (
                  <div ref={voyageRef} className="space-y-4 sm:space-y-6">
                    <h2 className={`text-3xl sm:text-4xl pb-4 lg:pb-8 font-medium ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                      All done!
                    </h2>
                    <p className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light px-1 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      You've completed the beacon intro! You now know how to:
                    </p>
                    <ul className={`text-base sm:text-lg leading-[1.8] sm:leading-relaxed font-light space-y-3 list-disc pl-4 sm:pl-6 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                      <li className="pl-2">Create beacons with custom data</li>
                      <li className="pl-2">Retrieve beacons using beacon keys</li>
                      <li className="pl-2">Link beacons together using voyages</li>
                      <li className="pl-2">Add descriptive info to your beacons</li>
                    </ul>
                    
                    <div className={`mt-8 p-6 rounded-lg ${isDark ? 'bg-gray-900/50' : 'bg-gray-50'}`}>
                      <h3 className={`text-xl font-medium mb-4 ${isDark ? 'text-emerald-500' : 'text-blue-600'}`}>
                        Premium Features Available
                      </h3>
                      <p className={`text-base leading-relaxed mb-4 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        While beacons are and will always be free, premium features can enhance your experience:
                      </p>
                      <ul className={`space-y-2 mb-6 ${isDark ? 'text-gray-300' : 'text-black'}`}>
                        <li className="flex items-center gap-2">
                          <div className={`w-1.5 h-1.5 rounded-full ${isDark ? 'bg-emerald-500' : 'bg-blue-600'}`}></div>
                          <span>Advanced voyage analytics</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <div className={`w-1.5 h-1.5 rounded-full ${isDark ? 'bg-emerald-500' : 'bg-blue-600'}`}></div>
                          <span>Extended beacon storage</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <div className={`w-1.5 h-1.5 rounded-full ${isDark ? 'bg-emerald-500' : 'bg-blue-600'}`}></div>
                          <span>Priority beacon network access</span>
                        </li>
                      </ul>
                      <button
                        onClick={handleAuthFlow}
                        className={`w-full px-4 py-2.5 rounded-lg flex items-center justify-center gap-2
                          ${isDark 
                            ? 'bg-emerald-500 hover:bg-emerald-600 text-black' 
                            : 'bg-blue-600 hover:bg-blue-700 text-white'}
                          transition-colors duration-200 text-sm`}
                      >
                        <span>{user ? 'View Profile' : 'Sign Up / Sign In'}</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
